<template>
    <div class="pa-6" v-if="opportunity">
        <v-card color="extralightgrey" class="cardOpportunity">
            <v-card-text class="pa-0">
                <v-row>
                    <v-col cols="4" class="pa-8">
                        <h2 class="primary--text mb-8">{{ $t('actualFunding') }}</h2>
                        <h4 class="primary--text">{{ $t('clientContract') }}</h4>
                        
                        <ul v-if="opportunity.data.current">
                            <li v-if="opportunity.data.current.financing.contract_type">{{$t('type')}} : <span class="black--text">{{opportunity.data.current.financing.contract_type}}</span></li>
                            <li v-if="opportunity.data.current.financing.nb_payments">{{$t('fundingDuration')}} : <span class="black--text">{{opportunity.data.current.financing.nb_payments}} {{ $t('month') }}</span></li>
                            <li v-if="opportunity.data.current.financing.annual_mileage && opportunity.data.current.financing.annual_mileage != 0">
                                {{$t('annualkm')}} : <span class="black--text">{{opportunity.data.current.financing.annual_mileage}}</span>
                            </li>
                            <li v-if="opportunity.data.current.price_TTC">{{$t('buyingprice')}} : <span class="black--text">{{opportunity.data.current.price_TTC}} €</span></li>
                            <li v-if="opportunity.data.current.financing.start_date">{{$t('buyingdate')}} : <span class="black--text">{{parseUsDateToFr(opportunity.data.current.financing.start_date)}}</span></li>
                            <li v-if="opportunity.data.current.financing.remaining_due">{{$t('remainingdue')}} : <span class="black--text">{{opportunity.data.current.financing.remaining_due}} €</span></li>
                            <li v-if="opportunity.data.current.financing.end_date">
                                {{$t('endFundingdate')}} : 
                                <span class="black--text">
                                    {{parseUsDateToFr(opportunity.data.current.financing.end_date)}}
                                </span>
                            </li>
                            
                        </ul>
                    </v-col>
                    <v-col cols="8" class="pa-8">
                        
                        <div class="d-flex align-start">
                            <div style="width:60%">
                                <h2 class="primary--text mb-8">{{ $t('actualCar') }}</h2>
                                <h4 class="primary--text">{{ opportunity.data.current.brand+ " " +opportunity.data.current.model }}</h4>
                                <ul>
                                    <li v-if="opportunity.data.current.version">{{$t('version')}} : <span class="black--text">{{opportunity.data.current.version}}</span></li>
                                    <li v-if="opportunity.data.current.motorisation">{{$t('motorisation')}} : <span class="black--text">{{opportunity.data.current.motorisation}}</span></li>
                                    <li v-if="opportunity.data.current.finish">{{$t('finition')}} : <span class="black--text">{{opportunity.data.current.finish}}</span></li>
                                    <li v-if="opportunity.data.current.body">{{$t('carrosserie')}} : <span class="black--text">{{opportunity.data.current.body}}</span></li>
                                    <li v-if="opportunity.data.current.plate">{{$t('immat')}} : <span class="black--text">{{opportunity.data.current.plate}}</span></li>
                                    <li v-if="opportunity.data.current.vin">{{$t('vin')}} : <span class="black--text">{{opportunity.data.current.vin}}</span></li>
                                    <li v-if="opportunity.data.current.energy">{{$t('energy')}} : <span class="black--text">{{opportunity.data.current.energy}}</span></li>
                                    <li v-if="opportunity.data.current.gear_type">{{$t('geartype')}} : <span class="black--text">{{opportunity.data.current.gear_type}}</span></li>
                                    <li v-if="opportunity.data.current.mileage">{{$t('km')}} : <span class="black--text">{{opportunity.data.current.mileage}} km</span></li>
                                </ul>
                            </div>
                            <div class="px-4"  style="width:40%">
                                <v-img max-height="250" style="border-radius:10px;" :src="opportunity.data.current.photo_1 ? opportunity.data.current.photo_1 : default_car"></v-img>
                                <v-card color="lightgrey" width="auto" class="no-shadow d-block py-4 mt-3">
                                    <v-card-text class="d-flex align-end justify-center pa-2">
                                        <h1 class="mb-0" style="height:auto; min-height:unset; line-height:auto;"><b>{{opportunity.data.current.financing.payment}} €</b></h1><span class="black--text">/{{ $t('month') }}</span>
                                    </v-card-text>
                                </v-card>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-card class="mt-4 cardOpportunity"  color="extralightgrey">
            <v-card-text class="pa-0">
                <v-row>
                    <v-col cols="4" class="pale_purple pa-8">
                        <h2 class="primary--text mb-8 mt-0">{{ $t('newFunding') }}</h2>
                        <ul v-if="proposal">
                            <li>{{$t('type')}} : <span class="black--text">LOA</span></li>
                            <li v-if="proposal.price_TTC">{{$t('buyingprice')}} : <span class="black--text">{{opportunity.data.proposal.price_TTC}} €</span></li>
                            <li v-if="proposal && proposal.financing && proposal.financing.nb_payments" class="d-flex align-center">
                                <div style="width:130px;">{{$t('fundingDuration')}} :</div>
                                <span class="black--text d-flex align-center ml-2" >
                                    <v-select  style="width:80px" dense :height="25" class="input-form" hide-details solo flat v-model="simulation.nb_payments" :items="nbLoyersSelect">
                                    </v-select>
                                    <span class="ml-2">{{ $t('month') }} </span>
                                </span>
                            </li>
                            <li v-if="proposal && proposal.financing && proposal.financing.annual_mileage && proposal.financing.annual_mileage != 0" class="d-flex align-center">
                                <div style="width:130px;">{{$t('annualkm')}} : </div>
                                <span class="black--text d-flex align-center ml-2" >
                                    <v-select  style="width:120px" dense class="input-form" hide-details solo flat v-model="simulation.annual_mileage" :items="nbkmSelect">
                                    </v-select>
                                </span>
                            </li>
                            <li v-if="proposal && proposal.financing && proposal.financing.rate" class="d-flex align-center">
                                <div style="width:130px;">{{$t('rate')}} : </div>
                                <span class="black--text d-flex align-center ml-2" >
                                    <v-text-field type="number" v-model="simulation.rate" style="width:120px" dense class="input-form" hide-details solo flat></v-text-field> %
                                </span>
                            </li>
                            <li v-if="proposal && proposal.financing && proposal.financing.first_payment" class="d-flex align-center">
                                <div style="width:130px;">{{$t('apport')}} : </div>
                                <span class="black--text d-flex align-center ml-2" >
                                    <v-text-field type="number" v-model="simulation.first_payment" style="width:120px" dense class="input-form" hide-details solo flat></v-text-field> €
                                </span>
                            </li>
                            <li v-if="proposal && proposal.financing && proposal.financing.buyback" class="d-flex align-center">
                                <div style="width:130px;">{{$t('reprise')}} : </div>
                                <span class="black--text d-flex align-center ml-2" >
                                    <v-text-field type="number" v-model="simulation.buyback" style="width:120px" dense class="input-form" hide-details solo flat></v-text-field> €
                                </span>
                            </li>
                        </ul>
                        <div class="mt-6 text-right">
                            <v-btn color="purple" dark depressed @click="simulateFunding">{{ $t('simulate') }}</v-btn>
                        </div>
                    </v-col>
                    <v-col cols="8" class="pa-8">
                        <h2 class="primary--text mb-8 d-flex justify-space-between align-start">
                            {{ $t('suggestedCar') }}
                            <v-btn depressed small color="purple" class="mr-4" dark @click="getForm('/library/getFilters?libType=car_alert&alert_id='+opportunity.id)">{{ $t('otherModel') }}</v-btn>
                        </h2>
                        <div class="d-flex align-start">
                            <div style="width:60%">
                                <h4 class="primary--text d-flex align-center">
                                    {{proposal.brand+ " " +proposal.model }}
                                    <v-chip small color="success" class="ml-2">{{ proposal.status == "catalogue" ? $t('inCatalogue') : $t('inStock') }}</v-chip>
                                </h4>
                                <ul>
                                    <li v-if="proposal.version">{{$t('version')}} : <span class="black--text">{{proposal.version}}</span></li>
                                    <li v-if="proposal.motorisation">{{$t('motorisation')}} : <span class="black--text">{{proposal.motorisation}}</span></li>
                                    <li v-if="proposal.finish">{{$t('finition')}} : <span class="black--text">{{proposal.finish}}</span></li>
                                    <li v-if="proposal.body">{{$t('carrosserie')}} : <span class="black--text">{{proposal.body}}</span></li>
                                    <li v-if="proposal.color">{{$t('color')}} : <span class="black--text">{{proposal.color}}</span></li>
                                    <li v-if="proposal.plate">{{$t('immat')}} : <span class="black--text">{{proposal.plate}}</span></li>
                                    <li v-if="proposal.energy">{{$t('energy')}} : <span class="black--text">{{proposal.energy}}</span></li>
                                    <li v-if="proposal.gear_type">{{$t('geartype')}} : <span class="black--text">{{proposal.gear_type}}</span></li>
                                    <li v-if="proposal.fiscal_power">{{$t('puissanceFiscale')}} : <span class="black--text">{{proposal.fiscal_power}} CV</span></li>
                                    <li v-if="proposal.real_power">{{$t('real_power')}} : <span class="black--text">{{proposal.real_power}} CV</span></li>
                                    <li v-if="proposal.mileage">{{$t('km')}} : <span class="black--text">{{proposal.mileage}} km</span></li>
                                    <li v-if="proposal.date_MEC && proposal.date_MEC != '1970-01-01'">{{$t('dateMEC')}} : <span class="black--text">{{parseUsDateToFr(proposal.date_MEC)}}</span></li>
                                    <li v-else-if="proposal.year">{{$t('dateMEC')}} : <span class="black--text">{{proposal.year}}</span></li>
                                </ul>
                               
                            </div>
                            <div class="px-4"  style="width:40%">
                                <div class="proposalImg mb-6">
                                    <v-img max-height="250" :src="proposal.photo_1 ? proposal.photo_1 : default_car"></v-img>
                                </div>
                                <v-card color="lightgrey" width="auto" class="no-shadow d-block py-4">
                                    <v-card-text class="d-flex align-end justify-center pa-2">
                                        <div class="reduction pa-2 py-1" v-if="proposal && proposal.financing && proposal.financing.diff_payment"><h4 class="mb-0 pb-0 white--text">{{ proposal.financing.diff_payment }} €</h4></div>
                                        <h1 class="mb-0" v-if="proposal && proposal.financing && proposal.financing.payment" style="height:auto; min-height:unset; "><b>{{proposal.financing.payment}} €</b></h1><span class="black--text">/{{ $t('month') }}</span>
                                    </v-card-text>
                                </v-card>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <VehicleDialogComponent v-if="form" ref="componentForm" :form="form"  @selectModel="selectModel"/>
    </div>
</template>
<script>
import dateManipulation from '@/mixins/dateManipulation'
import GenericDataService from '@/services/GenericDataService'
import VehicleDialogComponent from '../../../components/dialogs/VehicleDialogComponent.vue'
import getForm from "@/mixins/mixins"
export default {
    name: "opportunityTabComponent",
    props: ['opportunity'],
    mixins: [dateManipulation, getForm],
    components: {VehicleDialogComponent},
    data() {
        return {
            default_car : "https://cdn.media.upyourbizz.com/data/DEMO/00/7a/8e/30c288d97b-168249937116.jpeg",
            nbLoyersSelect: [24, 25, 36, 37, 48, 49, 60, 61],
            nbkmSelect: [10000, 15000, 20000, 25000, 30000, 35000, 40000, 450000],
            proposal: {},
            form:null,
            simulation: {
                alert_id: "",
                contract_type: "LOA",
                annual_mileage: "",
                rate: "",
                nb_payments: "",
                buyback: "",
                first_payment : ""
            }
        }
    },
    mounted() {
        if(this.opportunity && this.opportunity.data && this.opportunity.data.proposal){
            this.simulation.alert_id = this.opportunity.id
            this.simulation.annual_mileage = this.opportunity.data.proposal.financing.annual_mileage
            this.simulation.rate = this.opportunity.data.proposal.financing.rate
            this.simulation.nb_payments = this.opportunity.data.proposal.financing.nb_payments
            this.simulation.buyback = this.opportunity.data.proposal.financing.buyback
            this.simulation.first_payment = this.opportunity.data.proposal.financing.first_payment
            this.proposal = this._.cloneDeep(this.opportunity.data.proposal)
        }
    },
    methods: {
        selectModel(item){
            let payload = {
                data: {
                    current:  this._.cloneDeep(this.opportunity.data.current),
                    proposal: item
                }
            }
            GenericDataService.postData('/alert/updateAlert?alert_id='+this.opportunity.id, payload).then((response) => {
                this.$emit('refreshOpportunity');
            })
        },
        simulateFunding(){
            let payload = this._.cloneDeep(this.simulation)
            payload.force_stock_id = this.opportunity.data.proposal.id_vehicle
            GenericDataService.postData("/alert/getStock", payload).then((response) => {
                this.proposal = response.data.data[0]
                this.simulation.annual_mileage = this.proposal.financing.annual_mileage
                this.simulation.rate = this.proposal.financing.rate
                this.simulation.nb_payments = this.proposal.financing.nb_payments
                this.simulation.buyback = this.proposal.financing.buyback
                this.simulation.first_payment = parseFloat(this.proposal.financing.first_payment)
                //Comment du bloc le 20062023 car ca update l'alerte donc le mail que le client a recu se retrouve avec un autre véhicule
                // let payload = {
                //     data: {
                //         current:  this._.cloneDeep(this.opportunity.data.current),
                //         proposal: response.data.data[0]
                //     }
                // }
                // GenericDataService.postData('/alert/updateAlert?alert_id='+this.opportunity.id, payload)
            })
        }
    },
}
</script>
<style lang="scss">
    .cardOpportunity {
        overflow:hidden;
        h1 {
            line-height: 32px !important;
        }
        h2 {
            border-bottom:0;
            text-transform: none;
            height:auto;
            min-height:unset;
            font-size:22px;
        }
        h4 {
            border-bottom:0;
            text-transform: none;
            height:auto;
            min-height:unset;
            margin-bottom : 5px;
        }
        ul {
            padding-left:0;
            list-style:none;
            li{
                margin-bottom:5px;
            }
        }
        .proposalImg {
            border-radius:10px;
            overflow:hidden;
        }
        .reduction {
            position:absolute;
            border-radius:10px;
            background-color: var(--v-success-base);
            top:-10px;
            right:10px;
            h4 {
                font-size: 18px;
                font-weight:normal !important;
                height:auto; 
                min-height:unset; 
            }
        }
        .input-form .v-input__slot {
            background-color: #FFF !important;
        }
    }
</style>