<template>
    <div>
        <v-btn :disabled="disabled" large depressed :class="disabled ? 'btnFirstItem darkgrey--text' : 'btnFirstItem primary--text'" @click.stop="editItem(firstItem)" v-if="firstItem && firstItem.date_start">
            <v-icon :color="disabled ? 'darkgrey' : 'primary'" class="mr-3">
                {{firstItem.type == 'TODO' ? '$alarm_d' : (firstItem.type == 'RDV' ? '$calendar_d' : '$running_d')}}
            </v-icon>
            <b :class="disabled ? 'darkgrey--text' : 'primary--text'">{{parseFullDateToFr(firstItem.date_start)}}</b>
            <div v-if="firstItem.type == 'TODO'">
                <v-chip :class="disabled ? 'ml-3 white--text' : 'ml-3'" small :color="disabled ? 'darkgrey' : 'warning'" v-if="firstItem && firstItem.date_start && (new Date(firstItem.date_start) <= new Date())">{{$t('todeal')}}</v-chip>
                <v-chip :class="disabled ? 'ml-3 white--text' : 'ml-3'" small :color="disabled ? 'darkgrey' : 'primary'" v-if="firstItem && firstItem.date_start && (new Date(firstItem.date_start) > new Date())">{{$t('yettocome')}}</v-chip>
            </div>
            <div v-if="firstItem.type == 'RDV'">
                <v-chip :class="disabled ? 'ml-3 white--text' : 'ml-3'" small :color="disabled ? 'darkgrey' : 'warning'" v-if="firstItem && firstItem.status && firstItem.status == 'DRAFT'">{{$t('tobeconfirmed')}}</v-chip>
                <v-chip :class="disabled ? 'ml-3 white--text' : 'ml-3'" small :color="disabled ? 'darkgrey' : 'primary'" v-if="firstItem && firstItem.status && firstItem.status == 'CONFIRMED' && (new Date(firstItem.date_start) > new Date())">{{$t('yettocome')}}</v-chip> 
                <v-chip :class="disabled ? 'ml-3 white--text' : 'ml-3'" small :color="disabled ? 'darkgrey' : 'warning'" v-if="firstItem && firstItem.status && firstItem.status == 'CONFIRMED' && (new Date(firstItem.date_start) <= new Date())">{{$t('tobequalified')}}</v-chip> 
                <v-chip :class="disabled ? 'ml-3 white--text' : 'ml-3'" small :color="disabled ? 'darkgrey' : 'success'" v-if="firstItem && firstItem.status && firstItem.status == 'FINISHED' && firstItem.is_success">{{$t('cameRdv')}}</v-chip> 
                <v-chip :class="disabled ? 'ml-3 white--text' : 'ml-3'" small :color="disabled ? 'darkgrey' : 'error'" v-if="firstItem && firstItem.status && firstItem.status == 'FINISHED' && !firstItem.is_success">{{$t('notComeRdvs')}}</v-chip> 
            </div> 
            <div v-if="firstItem.type == 'PASSAGE'">
                <v-chip :class="disabled ? 'ml-3 white--text' : 'ml-3'" small :color="disabled ? 'darkgrey' : 'primary'" v-if="firstItem && firstItem.status && firstItem.status == 'CONFIRMED' && (new Date(firstItem.date_start) > new Date())">{{$t('yettocome')}}</v-chip> 
                <v-chip :class="disabled ? 'ml-3 white--text' : 'ml-3'" small :color="disabled ? 'darkgrey' : 'warning'" v-if="firstItem && firstItem.status && firstItem.status == 'CONFIRMED' && (new Date(firstItem.date_start) <= new Date())">{{$t('tobequalified')}}</v-chip> 
                <!-- <v-chip :class="disabled ? 'ml-3 white--text' : 'ml-3'" small :color="disabled ? 'darkgrey' : 'warning'" v-if="firstItem && firstItem.status && firstItem.status == 'CONFIRMED'">{{$t('tobequalified')}}</v-chip>  -->
                <v-chip :class="disabled ? 'ml-3 white--text' : 'ml-3'" small :color="disabled ? 'darkgrey' : 'success'" v-if="firstItem && firstItem.status && firstItem.status == 'FINISHED' && firstItem.is_success">{{$t('camePassage')}}</v-chip> 
                <v-chip :class="disabled ? 'ml-3 white--text' : 'ml-3'" small :color="disabled ? 'darkgrey' : 'error'" v-if="firstItem && firstItem.status && firstItem.status == 'FINISHED' && !firstItem.is_success">{{$t('notCamePassage')}}</v-chip> 
            </div>
        </v-btn>
        <v-menu :disabled="disabled" v-if="otherItems && otherItems.length > 0" offset-y left>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn large depressed style="min-width:44px;" class="btnFirstItem ml-2 pa-0 primary--text" v-bind="attrs" v-on="on" v-if="otherItems && otherItems.length > 0">
                        <b>+{{otherItems.length}}</b>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item v-for="(item, index) in otherItems" :key="'otherItem_'+index"  @click.stop="editItem(item)" link dense>
                        <v-icon color="primary" class="mr-3">
                            {{item.type == 'TODO' ? '$alarm_d' : (item.type == 'RDV' ? '$calendar_d' : '$running_d')}}
                        </v-icon>
                        <b class="primary--text">{{parseFullDateToFr(item.date_start)}}</b>
                        <div v-if="item.type == 'TODO'">
                            <v-chip class="ml-3" small color="warning" v-if="item && item.date_start && (new Date(item.date_start) <= new Date())">{{$t('todeal')}}</v-chip>
                            <v-chip class="ml-3" small color="primary" v-if="item && item.date_start && (new Date(item.date_start) > new Date())">{{$t('yettocome')}}</v-chip>
                        </div>
                        <div v-if="item.type == 'RDV'">
                            <v-chip class="ml-3" small color="warning" v-if="item && item.status && item.status == 'DRAFT'">{{$t('tobeconfirmed')}}</v-chip>
                            <v-chip class="ml-3" small color="primary" v-if="item && item.status && item.status == 'CONFIRMED' && (new Date(item.date_start) > new Date())">{{$t('yettocome')}}</v-chip> 
                            <v-chip class="ml-3" small color="primary" v-if="item && item.status && item.status == 'CONFIRMED' && (new Date(item.date_start) <= new Date())">{{$t('tobequalified')}}</v-chip> 
                            <v-chip class="ml-3" small color="success" v-if="item && item.status && item.status == 'FINISHED' && item.is_success">{{$t('cameRdv')}}</v-chip> 
                            <v-chip class="ml-3" small color="error" v-if="item && item.status && item.status == 'FINISHED' && !item.is_success">{{$t('notComeRdvs')}}</v-chip> 
                            <!-- <v-icon class="ml-3" v-if="item && item.status && item.status == 'FINISHED' && item.is_success" color="success">$check_l</v-icon>
                            <v-icon class="ml-3" v-if="item && item.status && item.status == 'FINISHED' && !item.is_success" color="error">$close</v-icon> -->
                        </div> 
                        <div v-if="item.type == 'PASSAGE'">
                            <v-chip class="ml-3" small color="primary" v-if="item && item.status && item.status == 'CONFIRMED' && (new Date(item.date_start) > new Date())">{{$t('yettocome')}}</v-chip> 
                            <v-chip class="ml-3" small color="primary" v-if="item && item.status && item.status == 'CONFIRMED' && (new Date(item.date_start) <= new Date())">{{$t('tobequalified')}}</v-chip>
                            <!-- <v-chip class="ml-3" small color="primary" v-if="item && item.status && item.status == 'CONFIRMED'">{{$t('tobequalified')}}</v-chip>  -->
                            <v-chip class="ml-3" small color="success" v-if="item && item.status && item.status == 'FINISHED' && item.is_success">{{$t('camePassage')}}</v-chip> 
                            <v-chip class="ml-3" small color="error" v-if="item && item.status && item.status == 'FINISHED' && !item.is_success">{{$t('notCamePassage')}}</v-chip> 
                            <!-- <v-icon class="ml-3" v-if="item && item.status && item.status == 'FINISHED' && item.is_success" color="success">$check_l</v-icon>
                            <v-icon class="ml-3" v-if="item && item.status && item.status == 'FINISHED' && !item.is_success" color="error">$close</v-icon> -->
                        </div>
                    </v-list-item>
                </v-list>
            </v-menu>
            <v-dialog v-model="qualifItem" width="600">
                <v-card color="lightgrey">
                    <v-toolbar tile flat dark  class="bg-gradient" style="max-height:64px;">
                        <v-toolbar-title v-if="object.type == 'PASSAGE'">{{object.type}} - {{parseFullDateToFr(object.date_start)}} / {{parseFullDateToFr(object.date_end)}} </v-toolbar-title>
                        <v-toolbar-title v-else>{{object.link}} - {{object.title}}</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-card-text class="pt-5 px-12" style="position:relative;">
                        <p v-if="object && object.start_date"><b>{{$t('start_date')}} : </b><span>{{parseFullDateToFr(object.start_date)}}</span></p>
                        <p v-if="object && object.date_start"><b>{{$t('start_date')}} : </b><span>{{parseFullDateToFr(object.date_start)}}</span></p>
                        <p v-if="object && object.date_end"><b>{{$t('end_date')}} : </b><span>{{parseFullDateToFr(object.date_end)}}</span></p>
                        <p><b>{{$t('comment')}} : </b> <span>{{object.comment}}</span></p>
                        <span v-if="object.type == 'PASSAGE' && object.status == 'FINISHED'">
                            <v-btn-toggle mandatory  color="primary" background-color="lightgrey" v-model="object.is_success" class="mr-2 " @click.stop="">
                                <v-btn outlined small @click.stop="setSuccess(1, object)" :value="'1'" style=" border-radius: 5px 0 0 5px !important;">
                                    <span>{{$t('passageCame')}}</span>
                                </v-btn>
                                <v-btn outlined small @click.stop="setSuccess(0, object)" :value="'0'" style="border-radius: 0 5px 5px 0 !important;">
                                    <span>{{$t('passageNotCame')}}</span>
                                </v-btn>
                            </v-btn-toggle>
                        </span>
                        <v-list color="lightgrey">
                            <v-list-item v-if="object.type == 'PASSAGE' && object.status != 'FINISHED'" style="border-radius:5px;" class="listItemButtons" link @click="editPassage">
                                <v-icon color="primary" class="mr-3">$pencil_d</v-icon>
                                {{$t('edit')}}
                            </v-list-item>
                            <v-list-item v-else-if="object.type == 'TODO'" style="border-radius:5px;" class="listItemButtons" link @click="getForm(todoEditFormUrl+object.id)">
                                <v-icon color="primary" class="mr-3">$pencil_d</v-icon>
                                {{$t('edit')}}
                            </v-list-item>
                            <v-list-item style="border-radius:5px;" class="listItemButtons" link @click="launchAction(index, button)" v-for="(button, index) in buttons" :key="'buttons_'+index">
                                <v-icon color="primary" class="mr-3">{{button.icon}}</v-icon>
                                {{$t(index)}}
                            </v-list-item>
                        </v-list>
                        
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-dialog class="rounded-lg" persistent width="500" v-model="confirmDialog">
                <v-card>
                    <v-toolbar tile flat dark  class="bg-gradient" >
                        <div style="display:flex; width:100%; align-items:center; justify-content:space-between;">
                            <div>
                                <v-toolbar-title>{{$t(confirmTitle)}}</v-toolbar-title>
                            </div>
                            <div>
                                <v-btn outlined dark depressed @click.native="closeConfirm">{{$t('cancel')}}</v-btn>
                                <v-btn outlined dark depressed @click="confirmAction" class="ml-3">{{$t('confirm')}}</v-btn>
                            </div>
                        </div> 
                    </v-toolbar>
                    <v-card-text class="mt-4">
                        {{$t(confirmText)}}
                    </v-card-text>
                </v-card>
            </v-dialog>
            <FormDialogComponent
            v-if="form"
            ref="componentForm"
            :form="form"
            :action="'SET_DATA'"
            />
    </div>
</template>
<script>
import GenericDataService from '@/services/GenericDataService'
import dateManipulation from '@/mixins/dateManipulation'
import FormDialogComponent from '@/components/dialogs/FormDialogComponent'
import getForm from "@/mixins/mixins";

export default {
    name: "objectsDemandComponent",
    props: ['objects', 'rdvs_todo', 'disabled'],
    mixins:[dateManipulation, getForm],
    components:{FormDialogComponent},
    data() {
        return {
            form:null,
            qualifItem: false,
            todoEditFormUrl: "/todo/editForm?id=",
            excerpt:{},
            object:{},
            buttons:[],
            confirmDialog:false,
            confirmTitle : "",
            confirmText : "",
            actionToConfirm : {}
        }
    },
    created() {
        //this.refreshDataObjects()
    },
    mounted() {
        //this.refreshDataObjects()
    },
    watch: {
        
    },
    computed: {
        firstItem(){
            if(this.objects && this.objects.length > 0){
                return this.objects[0]
            } else {
                return {}
            }
           
        },
        otherItems(){
            if(this.objects && this.objects.length > 1){
            
                return this.objects.filter((e) => e.id != this.firstItem.id)
            } else {
                return []
            }
        }
    },
    methods: {
        setSuccess(value, object){
            GenericDataService.postData('/rdv/setSuccess?id='+object.id, { is_success: value}).then((response) => {
                this.customRefresh();
            })
        },
        editPassage(){
            this.$emit('editPassage', this.object)
            setTimeout(() => {
                this.closeModal()
            }, 150);
        },
        editItem(item){
            if(item.type == 'RDV'){
                GenericDataService.getData('/rdv/'+item.id+"/get").then((response) => {
                    this.$emit('editRdv', response.data.data) 
                })
            } else if (item.type == 'TODO'){
                GenericDataService.getData('/todo/getOne?id='+item.id).then((response) => {
                    this.object = response.data.data[0]
                    GenericDataService.getData("todo/getButtonList?todo_id="+item.id).then((response) => { 
                        this.buttons = response.data.data
                        GenericDataService.getData('/todo/getExcerptByTodo?todo_id=' + item.id).then((response) => {
                            this.excerpt = response.data.data
                            this.qualifItem = true
                        });
                    })
                })
                
                
                //this.$emit('editTodo', this.todoEditFormUrl+item.id) 
            } else if (item.type == 'PASSAGE') {
                if(this.rdvs_todo){
                    let todo = this.rdvs_todo.find((e) => e.module_id == item.id)
                    GenericDataService.getData('/rdv/'+item.id+"/get").then((response) => {
                        this.object = response.data.data
                        GenericDataService.getData("todo/getButtonList?todo_id="+todo.id).then((response) => {
                            this.buttons = response.data.data
                            GenericDataService.getData('/todo/getExcerptByTodo?todo_id=' + todo.id).then((response) => {
                                this.excerpt = response.data.data
                                this.qualifItem = true
                            });
                        })
                        //this.$emit('editPassage', response.data.data)
                    })
                } else {
                     GenericDataService.getData('/rdv/'+item.id+"/get").then((response) => {
                        this.object = response.data.data
                        this.buttons = []
                        this.qualifItem = true
                        //this.$emit('editPassage', response.data.data)
                    })
                }
                
            }
        },
        customRefresh(response){
            this.$emit('editTodo') 
            this.closeModal()
        },
        // refreshDataObjects(){
        //     this.data = this._.cloneDeep(this.objects)
        //     this.data.forEach(element => {
        //         if(element.type == 'RDV'){
        //             GenericDataService.getData('/rdv/'+element.id+"/get").then((response) => {
        //                 element.data = response.data.data
        //             })
        //         } else if(element.type == 'TODO') {
        //             GenericDataService.getData('/todo/'+element.id+"/get").then((response) => {
        //                 element.data = response.data.data.todos.find((e) => e.id == element.id)
        //             })
        //         }
        //     });
        // }
        closeModal(){
            this.object = {}
            this.buttons = {}
            this.excerpt = {}
            this.qualifItem = false
        },
        confirmAction(){
            if(this.actionToConfirm.action == "post"){
                let url = this.actionToConfirm.postUrl.replace('{{id}}', this.object.id)
                GenericDataService.postData(url).then((response) => {
                    this.confirmDialog = false
                    this.customRefresh(response)
                })
            } 
        },
        closeConfirm(){
            this.confirmDialog = false
            this.confirmTitle = ""
            this.confirmText = ""
            this.actionToConfirm = {}
        },
        launchAction(index, action){
            if(action.confirm && action.confirm.hasOwnProperty('title')){
                this.confirmDialog = true
                this.confirmTitle = action.confirm.title
                this.confirmText = action.confirm.text
                this.actionToConfirm = action
            } else {
                if (action.action == "form"){
                    let url = action.formUrl.replace('{{id}}', this.object.id)
                    this.getForm(url)
                } 
            }
        },
    },
}
</script>
<style lang="scss">
    .btnFirstItem {
        border:1px solid var(--v-lightgrey-base) !important;
        background-color: var(--v-extralightgrey-base) !important;
        border-color: var(--v-lightgrey-base) !important;
    }
</style>