<template>
    <div>
        <v-dialog  v-model="openRdvDialog" persistent max-width="900px">
            <v-card rounded="lg">
                <v-toolbar tile dark color="primary" class='bg-gradient'>
                    <v-toolbar-title>{{$t(title)}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn depressed small class="mr-3" v-if="rdvRegistered.status != 'FINISHED'" @click="openDeleteRdv" color="error">
                        {{$t('delete')}}
                    </v-btn>
                    <v-btn depressed outlined color="white" small class="mr-3" v-if="rdvRegistered.status != 'FINISHED'" :loading="recordRdvButton" @click="rdvRegistered.status == 'CONFIRMED' ? recordRdv(true) : recordRdv()">
                        {{$t('recordRdvLabel')}}
                    </v-btn>
                    <v-btn depressed outlined color="white" small class="mr-3" v-show="(!rdvRegistered.status || rdvRegistered.status != 'CONFIRMED') && rdvRegistered.status != 'FINISHED'" :disabled="rdvCanBeConfirmed" :loading="recordRdvButton" @click="recordRdv(true)">
                        {{$t('confirmAndRecordRdvLabel')}}
                    </v-btn>
                    <v-btn depressed outlined color="white" small class="mr-3" v-show="rdvRegistered.status && rdvRegistered.status == 'CONFIRMED' && rdvRegistered.status != 'FINISHED' && (new Date(rdvRegistered.date_start) <= new Date())" :loading="recordRdvButton" @click="getForm('/rdv/'+rdvRegistered.id+'/getForm?formName=FEEDBACK_RDV')">
                        {{$t('qualifyRdv')}}
                    </v-btn>
                    <span v-if="rdvRegistered.status == 'FINISHED'">
                        <v-btn-toggle mandatory outlined color="white" background-color="transparent" v-model="is_success" class="mr-2 btnToggleStats" @click.stop="">
                            <v-btn outlined small @click.stop="setSuccess(1)" :value="1" style="border-color: rgba(255, 255, 255, 1) !important; border-radius: 5px 0 0 5px !important;">
                                <span>{{$t('rdvCame')}}</span>
                            </v-btn>
                            <v-btn outlined small @click.stop="setSuccess(0)" :value="0" style="border-color: rgba(255, 255, 255, 1) !important; border-radius: 0 5px 5px 0 !important;">
                                <span>{{$t('rdvNotCame')}}</span>
                            </v-btn>
                        </v-btn-toggle>
                    </span>
                     <v-btn depressed text color="white" class="square" @click="closeRecordRdv">
                       <v-icon>$close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="pt-6 ">
                    <v-container>
                        
                        <v-row>
                            <v-col cols="12"  class="rdvDetail">
                                <v-btn depressed color="white" width="100%" class="justify-start btnRdvEditor" @click="visioUnavailableFct" v-click-outside="closeVisio">
                                    <v-icon small color="black">{{rdvInfo.visio ? '$webcam_r' : '$webcam_slash_r'}}</v-icon>
                                    <span class="ml-4 black--text labelSelect" v-if="!displayVisioSelect">{{rdvInfo.visio == "1" ? 'Visio' : 'Présentiel'}}</span>
                                    <span class="" v-else>
                                        <v-autocomplete class="ma-0 ml-3" v-if="rdvInfo && (rdvInfo.visio || !rdvInfo.visio)" dense hide-details @change="changeVisioState" :items="visioSelect" ref="visioSelectRef" v-model="rdvInfo.visio" :no-data-text="$t('no-data-text')"></v-autocomplete>
                                    </span>
                                    <transition name="fade">
                                        <span class="error--text ml-2" style="text-transform:none; font-size:12px; letter-spacing:normal;" v-if="visioUnavailable">Ce vendeur n'est pas disponible en visio</span>
                                    </transition>
                                </v-btn>
                                <v-btn depressed color="white" @click="editDatePlace" width="100%" class="justify-start btnRdvEditor">
                                    <v-icon small color="black">$clock_l</v-icon>
                                    <span class="ml-4 black--text labelSelect" v-if="rdvInfo && rdvInfo.date">{{parseUsDateToFr(rdvInfo.date)}} {{rdvInfo.start.substr(0,5)}} - {{rdvInfo.end.substr(0,5)}}</span>
                                </v-btn>
                                <v-btn depressed color="white" width="100%"  v-if="$func.hasRight('lead/update_duration')" @click="openRdvDuration" class="justify-start btnRdvEditor" v-click-outside="closeDuration">
                                    <v-icon small color="black">$hourglass_l</v-icon>
                                    <span v-if="!displayDurationSelect">
                                        <span class="ml-4 black--text labelSelect" v-if="rdvInfo.start">{{(parseHourToFloat(rdvInfo.end) - parseHourToFloat(rdvInfo.start))*60}} min</span>
                                    </span>
                                    <span v-else>
                                        <v-select v-model="duration_selected" :items="duration_available" @change="editDuration" return-object class="ma-0 ml-3" dense hide-details></v-select>
                                    </span>
                                </v-btn>
                                <v-btn depressed color="white" width="100%"  v-else class="justify-start btnRdvEditor">
                                    <v-icon small color="black">$hourglass_l</v-icon>
                                    <span class="ml-4 black--text labelSelect" v-if="rdvInfo.start">{{(parseHourToFloat(rdvInfo.end) - parseHourToFloat(rdvInfo.start))*60}} min</span>
                                </v-btn>
                                <v-btn depressed  color="white" width="100%" class="justify-start btnRdvEditor" @click="selectOrEditVendor">
                                <v-icon small :color="(rdvInfo.ref && rdvInfo.ref.name) ? 'black' : 'warning'">$user_tie_l</v-icon>
                                    <span class="ml-4 black--text labelSelect" v-if="rdvInfo.ref && rdvInfo.ref.name">{{rdvInfo.ref.firstname}} {{rdvInfo.ref.name}}</span>
                                    <span class="ml-4 darkgrey--text labelSelect" v-else>{{$t('selectVendorRdv')}}</span>
                                </v-btn>
                                
                                <v-btn depressed v-if="rdvInfo.place && (rdvInfo.visio == '0' || !rdvInfo.visio)" color="white" @click="editDatePlace" width="100%" class="justify-start adressBtn btnRdvEditor">
                                    <v-icon small color="black">$map_l</v-icon>
                                    <div class="adressField" v-if="rdvInfo.place && rdvInfo.place.config">
                                        <p class="ml-4 mb-0 black--text labelSelect">{{ rdvInfo.place.config.name }}</p>
                                        <p class="ml-4 mb-0 black--text labelSelect" v-html="rdvInfo.place.config.adress"></p>
                                        <p class="ml-4 mb-0 black--text labelSelect">{{ rdvInfo.place.config.zip + " - " +rdvInfo.place.config.city}}</p>
                                    </div>
                                    <div v-else>
                                        <p class="ml-4 mb-0 black--text labelSelect">{{'noPlaceSelected'}}</p>
                                    </div>
                                </v-btn>
                                <v-btn depressed v-if="rdvInfo.visio == '1'" color="white" width="100%" target="_blank" :href="rdvRegistered.visioLink" class="justify-start adressBtn btnRdvEditor mt-2">
                                    <v-icon small color="black">$webcam_r</v-icon>
                                    <p class="ml-4 mb-0 black--text labelSelect" style="font-size:16px;">{{rdvRegistered.visioLink}}</p>
                                </v-btn>
                                <v-btn depressed v-if="operation && operation.config && operation.config.rdv_with_ressource" color="white" width="100%" class="justify-start btnRdvEditor" @click="selectOrEditRessource">
                                    <v-icon small color="warning">$inventory_l</v-icon>
                                    <span class="ml-4 black--text labelSelect" v-if="rdvInfo.ressource && rdvInfo.ressource.id">{{rdvInfo.ressource.label}}</span>
                                    <span class="ml-4 darkgrey--text labelSelect" v-else>Ajouter une ressource</span>
                                </v-btn>
                                <h4 class="mt-4">CONTACT</h4>
                                <v-btn depressed color="white" width="100%" class="justify-start btnRdvEditor white mt-2">
                                    <v-icon small :color="(rdvInfo && rdvInfo.contact && rdvInfo.contact.text) ? 'black' : 'warning'">$user_l</v-icon>
                                    <span class="ml-4 black--text labelSelect" v-if="rdvInfo && rdvInfo.contact && rdvInfo.contact.text">{{rdvInfo.contact.text}}</span>
                                    <span class="ml-4 darkgrey--text labelSelect" v-else>{{$t('noContactSelected')}}</span>
                                </v-btn>
                                <v-btn depressed color="white" width="100%" @click="openSelectContactDialog = true" class="justify-start btnRdvEditor">
                                <v-icon small :color="(rdvInfo.accompagnants && rdvInfo.accompagnants > 0 ) ? 'darkgrey' : 'warning'">$plus_l</v-icon>
                                    <span class="ml-4 darkgrey--text labelSelect" v-if="!rdvInfo.accompagnants || rdvInfo.accompagnants == 0">Ajouter des accompagnants</span>
                                    <span class="ml-4 black--text labelSelect" v-else>{{rdvInfo.accompagnants}} <span v-if="rdvInfo.accompagnants == 1">Accompagnant</span> <span v-else>Accompagnants</span></span>
                                </v-btn>
                                <v-btn depressed color="white" width="100%" @click="openSelectContactDialog = true" :class="(!rdvInfo.demands || rdvInfo.demands.length == 0) ? 'justify-start btnDemands noDemands btnRdvEditor' : 'justify-start btnDemands btnRdvEditor'">
                                    <v-icon small :color="(rdvInfo.demands && rdvInfo.demands.length > 0) ? 'black' : 'warning'">$handshake_l</v-icon>
                                    <span class="ml-4 darkgrey--text labelSelect " v-if="!rdvInfo.demands || rdvInfo.demands.length == 0">Ajouter des demandes</span>
                                    <span class="ml-4 py-2 black--text labelSelect demandsDisplay" v-else>
                                        <div v-for="demand in rdvInfo.demands" :key="demand.id">
                                            #D{{demand.id}} - {{demand.name}}
                                        </div>
                                    </span>
                                </v-btn>
                                <v-textarea v-model="rdvRegistered.comment" :label="$t('comment')" class="mt-2" filled hide-details></v-textarea>
                            </v-col>
                        </v-row>
                    </v-container>
                    
                    <SelectVendorRdvDialogComponent :multi="possibleVendors" :rdvInfo="rdvInfo" :contact="contact" :displaySelectVendor="displaySelectVendor" @closeVendorDialog="closeVendorDialog" @confirmVendorSelected="confirmVendorSelected"/>
                    <SelectRessourceRdvDialogComponent :ressources="ressources" :ressourceSelected="rdvInfo.ressource" :displaySelectRessources="displaySelectRessources" @closeRessource="closeRessourceDialog" @selectRessource="selectRessource"/>
                    <SelectContactRdvDialogComponent v-if="rdvRegistered && rdvInfo.contact" :openSelectContactDialog="openSelectContactDialog" @close="closeSelectContactDialog" @saveContactAccompDemands="saveContactAccompDemands" :contact="rdvInfo.contact" :accompagnants="rdvRegistered.accompagnants" :demandsSelected="rdvInfo.demands"/>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="cancelDialog" width="400">
            <v-card>
                <v-toolbar tile flat dark  class="bg-gradient">
                    <v-toolbar-title>{{$t('cancelRdv')}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn outlined dark small depressed @click.native="closeCancelDialog">{{$t('cancel')}}</v-btn>
                    <v-btn depressed dark small color="error" @click="deleteRdv" class="ml-3">{{$t('delete')}}</v-btn>
                </v-toolbar>
                <v-card-text>
                    <div class="mt-6">
                        <v-form v-model="validCancel" ref="cancelForm">
                            <v-textarea v-model="cancelReason" class="mt-2 required" :label="$t('cancelReason')" required filled 
                            :rules="[v => (v != '') ? true : $t('cancelReasonError')]"></v-textarea>
                        </v-form>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
        <FormDialogComponent
            v-if="form"
            ref="componentForm"
            :type="'edit'"
            :form="form"
            :action="'SET_DATA'"
        />
    </div>
</template>
<script>
import dateManipulation from '@/mixins/dateManipulation'
import mixins from '@/mixins/mixins'
import GenericDataService from '@/services/GenericDataService'
import FormDialogComponent from '@/components/dialogs/FormDialogComponent'
import SelectVendorRdvDialogComponent from '@/components/dialogs/SelectVendorRdvDialogComponent'
import SelectContactRdvDialogComponent from '@/components/dialogs/SelectContactRdvDialogComponent'
import SelectRessourceRdvDialogComponent from '@/components/dialogs/SelectRessourceRdvDialogComponent'
export default {
    name:"RdvFormDialogComponent",
    props:['appeal_id', 'openRdvDialog', 'contact', "title", "multi", "rdvInfo", "operationId", "rdvRegistered"],
    components: {SelectVendorRdvDialogComponent, SelectContactRdvDialogComponent, SelectRessourceRdvDialogComponent, FormDialogComponent},
    mixins:[dateManipulation, mixins],
    data() {
        return {
            form: null,
            publicPath: process.env.BASE_URL,
            displayDurationSelect:false,
            recordRdvButton: false,
            contactItems: [],
            searchContact: "",
            loadingContact: false,
            places:[],
            infos: {},
            ressources: [],
            visioUnavailable: false,
            displayVisioSelect: false,
            displayCreneauSelect:false,
            creneauUnavailable: false,
            displaySelectRessources: false,
            openSelectContactDialog: false,
            placeUnavailable: false,
            displayPlaceSelect: false, 
            displaySelectVendor: false,
            visioCreneaux: false,
            rdvCanBeConfirmed: false,
            operation: {},
            possibleVendors:[],
            visioSelect: [
                {
                    text: "Visio",
                    value: "1"
                },
                {
                    text: "Présentiel",
                    value: "0"
                }
            ],
            cancelDialog: false,
            validCancel: false,
            cancelReason: "",
            is_success: "",
            duration_available: [],
            duration_selected:{}
        }
    },
    mounted() {
        if(this.rdvInfo && this.rdvInfo.ref){
            this.places = this.rdvInfo.ref.places
            if(!this.rdvInfo.place){
                this.rdvInfo.place = this.places[0]
            }
        }
        if(this.rdvInfo && this.operationId && this.operationId != 0){
            GenericDataService.getData('/operation/'+this.operationId+'/get').then((response) => {
                this.operation = response.data.data
            })
        }
        
        this.checkIfCanConfirm()
    },
    methods: {
       
        setSuccess(success){
            this.recordRdvButton = true
            GenericDataService.postData('/rdv/setSuccess?id='+this.rdvRegistered.id, { is_success: success}).then((response) => {
                this.recordRdvButton = false
                this.$emit('refreshRdv')
            }).catch((e) => {
                        this.recordRdvButton = false
                    })
        },
        customRefresh(){
             this.$emit('refreshRdv')
        },
        deleteRdv(){
            if(this.$refs.cancelForm.validate()){
                let rdv = this._.cloneDeep(this.rdvRegistered)
                rdv.is_closed = 1
                rdv.closed_reason = this.cancelReason
                rdv.status = "CANCELLED"
                this.recordRdvButton = true
                GenericDataService.postData('/rdv/set?addHistory=true&id='+this.rdvRegistered.id+(this.appeal_id ? "&appeal_id="+this.appeal_id : ""), rdv).then((response) => {
                    this.cancelReason = ""
                    this.cancelDialog = false
                    this.recordRdvButton = false
                    this.$emit('refreshRdv')
                }).catch((e) => {
                        this.recordRdvButton = false
                    })
            }
        },
        closeCancelDialog(){
            this.cancelReason = ""
            this.cancelDialog = false
        },
        openDeleteRdv(){
            this.cancelDialog = true
        },
        recordRdv(confirmRdv = false) {
            let rdv = this._.cloneDeep(this.rdvRegistered)
            if(confirmRdv){
                rdv.status = "CONFIRMED"
            } else {
                 rdv.status = "DRAFT"
            }
            this.recordRdvButton = true
            GenericDataService.postData('/rdv/set?addHistory=true'+(this.appeal_id ? "&appeal_id="+this.appeal_id : ""), rdv).then((response) => {
                this.recordRdvButton = false
                this.$emit('refreshRdv')
            }).catch((e) => {
                        this.recordRdvButton = false
                    })
        },
        closeRecordRdv() {
            this.$emit('close')
        },

        closeVendorDialog() {
            this.displaySelectVendor = false
        },
        selectRessource(ressource){
            if(ressource){
                this.rdvInfo.ressource = ressource
                this.rdvRegistered.ressource = ressource
                this.recordRdvButton = true
                GenericDataService.postData('/rdv/set?id='+this.rdvRegistered.id+(this.appeal_id ? "&appeal_id="+this.appeal_id : ""), this.rdvRegistered).then((e) => {
                    this.recordRdvButton = false
                    this.displaySelectRessources = false
                }).catch((e) => {
                        this.recordRdvButton = false
                    })
            }
            this.checkIfCanConfirm()
            this.displaySelectRessources = false
        },
        closeRessourceDialog() {
            this.displaySelectRessources = false
        },
        closeSelectContactDialog(){
            this.openSelectContactDialog = false
        },
        confirmVendorSelected(selectedVendor) {
            if(selectedVendor != ""){
                this.$nextTick(() => {
                    let vendor = this._.cloneDeep(this.multi.find((e) => e.id == selectedVendor))
                    this.rdvInfo.ref_id = selectedVendor
                    this.rdvInfo.ref = vendor
                    this.rdvRegistered.links.user = [parseInt(selectedVendor)]
                    if(this.rdvInfo.visio == "1"){
                        if(!vendor.confVisio){
                            this.rdvInfo.visio = "0"
                            this.rdvRegistered.visio = "0"
                        }
                    }
                    this.recordRdvButton = true
                    GenericDataService.postData('/rdv/set?id='+this.rdvRegistered.id+(this.appeal_id ? "&appeal_id="+this.appeal_id : ""), this.rdvRegistered).then((e) => {
                        this.recordRdvButton = false
                        this.displaySelectVendor = false
                        this.checkIfCanConfirm()
                    }).catch((e) => {
                        this.recordRdvButton = false
                    })
                });
            }
        },
        saveContactAccompDemands(e){
            this.rdvInfo.accompagnants = e.accompagnant
            //this.rdvInfo.contact = e.contact
            this.rdvInfo.demands = e.demands
            this.openSelectContactDialog = false
            this.$nextTick(() => {
                this.rdvRegistered.links.demand = e.demands.map((e) => parseInt(e.id))
                this.rdvRegistered.accompagnants = this.rdvInfo.accompagnants
                GenericDataService.postData('/rdv/set?id='+this.rdvRegistered.id+(this.appeal_id ? "&appeal_id="+this.appeal_id : ""), this.rdvRegistered).then((e) => {
                    this.$forceUpdate()
                    this.checkIfCanConfirm()
                })
            });
        },
        changeVisioState(){
            this.rdvRegistered.visio = this.rdvInfo.visio
            if(new Date().getTime() < new Date(this.rdvRegistered.date_start).getTime() || this.rdvRegistered.status == 'CONFIRMED'){
                this.rdvRegistered.status = "DRAFT"
            }
            this.checkIfCanConfirm()
            GenericDataService.postData('/rdv/set?id='+this.rdvRegistered.id+(this.appeal_id ? "&appeal_id="+this.appeal_id : ""), this.rdvRegistered)
        },
        editDuration(){
            this.rdvInfo.end =  this.parseFloatToHour(this.parseHourToFloat(this.rdvInfo.start) + (this.duration_selected.value / 60))
            this.rdvRegistered.date_end =  this.rdvRegistered.date_end.split(" ")[0]+" "+this.parseFloatToHour(this.parseHourToFloat(this.rdvInfo.start) + (this.duration_selected.value / 60))+":00"
            GenericDataService.getData('/rdv/'+this.rdvRegistered.id+'/setRdvDuration?duration='+this.duration_selected.value)
        },
        selectOrEditVendor() {
            GenericDataService.getData('/operation/'+this.operationId+'/getTeamPlanningsDispo?place_id='+this.rdvInfo.place.id+'&from='+this.rdvInfo.date+' '+this.rdvInfo.start+'&to='+this.rdvInfo.date+' '+this.rdvInfo.end+(this.rdvInfo.id ? '&excludeRdv='+this.rdvInfo.id : '')).then((response) => {
                this.possibleVendors = response.data.data
                this.displaySelectVendor = true
            })
        },
        selectOrEditRessource() {
            this.displaySelectRessources = true
        },
        visioUnavailableFct(){
            if(!this.rdvInfo.ref || !this.rdvInfo.ref.confVisio){
                this.visioUnavailable = true;
                setTimeout(() => {
                    this.visioUnavailable = false
                }, 2000);
            } else {
                this.displayVisioSelect = true
            }
        },
        closeDuration(){
            if(this.displayDurationSelect){
                this.displayDurationSelect = false
            }
        }, 
        closeVisio(){
            if(this.displayVisioSelect){
                this.displayVisioSelect = false
            }
        },
        closeCreneau(){
            if(this.displayCreneauSelect){
                this.displayCreneauSelect = false
            }
        },
        editDatePlace(){
            this.$emit('editDatePlace')
        },
        checkIfCanConfirm(){
            if(
                this.rdvRegistered &&
                //check qu'on a un vendeur
                this.rdvRegistered.links && this.rdvRegistered.links.user && this.rdvRegistered.links.user.length > 0 && !isNaN(this.rdvRegistered.links.user[0]) &&
                //check qu'on a un lieu
                this.rdvRegistered.place && this.rdvRegistered.place.hasOwnProperty('id') &&
                //check d'une date heure de debut et de fin
                this.rdvRegistered.date_start != "" && this.rdvRegistered.date_end != ""
                ) {
                    //check si l'opération a besoin de ressources et si elles sont présentes
                    if(this.operation && this.operation.config && this.operation.config.rdv_with_ressource){
                        //TODO Check if not Ressources return true
                    }
                 this.rdvCanBeConfirmed = false
            } else {
                this.rdvCanBeConfirmed = true
            }
        },
        openRdvDuration(){
            if(this.$func.hasRight('lead/update_duration')){
                GenericDataService.getData('/rdv/'+this.rdvRegistered.id+'/getRdvDurationAvailable').then((response) => {
                    this.duration_available = response.data.data
                    this.duration_selected = response.data.data.find((e) => e.selected)
                    this.displayDurationSelect = true
                })
            }
        }
    },
    computed: {
        demandSelected: function(){
            return this.rdvInfo.demands
        }
    },
    watch: {
        rdvRegistered: {
            deep:true,
            handler(val){
                this.checkIfCanConfirm()
            }
        },
        openRdvDialog(val){
            if(val){
                this.is_success = this.rdvRegistered.is_success ? parseInt(this._.cloneDeep(this.rdvRegistered.is_success)) : 0
                if(this.rdvInfo.ref && this.rdvInfo.ref.places){
                    this.places = this.rdvInfo.ref.places
                    if(!this.rdvInfo.place){
                        this.rdvInfo.place = this.places[0]
                    }
                }
                if(this.rdvInfo && this.operationId && this.operationId != 0){
                    GenericDataService.getData('/operation/'+this.operationId+'/get').then((response) => {
                        this.operation = response.data.data
                    })
                    GenericDataService.getData('/operation/'+this.operationId+'/getRessourcesByPlaces?place_id='+this.rdvInfo.place.id).then((response) => {
                        this.ressources = response.data.data
                    })
                }
                
                this.checkIfCanConfirm()
            }
        }
    },
}
</script>
<style lang="scss">
    .v-input__icon--append .v-icon {
        transform: none !important;
    }
    .rdvDetail {
        .v-btn {
            height:30px !important;
        }
        .adressBtn {
            height:auto !important;
        }
        .btnDemands {
            height:auto !important;
            &.noDemands {
                height: 30px !important;
            }
        }
        .adressField {
            display:flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        }
        .v-btn--disabled {
            background-color:rgba(0,0,0,0) !important;
            &.v-btn--has-bg {
                background-color:rgba(0,0,0,0) !important;
            }
        }
        .theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
            background-color:rgba(0,0,0,0) !important;
        }
        .btnRdvEditor {
            font-weight: normal !important;
            letter-spacing: 1px;
        }
    }
</style>