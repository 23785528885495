<template>
    <div>
        <h2 class="icon-title mb-0" style="width:100%; align-items:center;">
        {{ $t("pointsOfInterest") }}

        <div class="userMenu">
            <v-btn small depressed class="square mr-2" color="transparent" @click="getForm(formUrl)">
                <v-icon dark small> $pencil_d </v-icon>
            </v-btn>
            <span class="globalPoints">
                {{ points.total}} {{ $t("pt") }}
            </span>
        </div>
      </h2>
        <div class="width: 100%;">
            <HorizontalBarComponent v-if="points && points.datasets && !points.allEmpty" :dataSet="points" style="height:100%; width:100%;"/>
            <div class="pb-1" v-else>
                <v-alert class="mt-2 mx-4" border="left" color="info" text>
                    {{ $t('emptyPointsInfoMessage') }}
                </v-alert>
            </div>
        </div>

        <FormDialogComponent v-if="form" ref="componentForm" type="edit" :form="form" :action="'SET_DATA'" />
    </div>
</template>
<script>
import GenericDataService from '@/services/GenericDataService';
import FormDialogComponent from "@/components/dialogs/FormDialogComponent";
import getForm from "@/mixins/mixins";
import refreshData from "@/mixins/mixins";
import HorizontalBarComponent from '@/components/graphs/HorizontalBarComponent';
export default {
    name: "statsTabComponent",
    components: {FormDialogComponent, HorizontalBarComponent},
    mixins: [getForm, refreshData],
    data() {
        return {
        form: null,
        formUrl: "/contact/" + this.$route.params.contact_id + "/getPointsForm",
        apiUrl: "/contact/" + this.$route.params.contact_id + "/getPointsStats",
        mutation: "contact/setPointsData",
        };
    },
    created() {
        this.refreshData();
    },
    watch: {
        $route(to, from) {
        this.formUrl = "/contact/" + this.$route.params.contact_id + "/getPointsForm";
        this.apiUrl = "/contact/" + this.$route.params.contact_id + "/getPointsStats";
        this.refreshData();
        },
    },
    methods: {},
    computed: {
        points: function () {
        return this.$store.state.contact.points;
        },
    },
}
</script>