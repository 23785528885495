<template>
    <div class="interactionClient">
        <h1>{{$t('clientInteractions' )}}</h1>
        <div class="btnInteraction d-flex justify-end flex-wrap mb-2" v-if="demand.is_closed != '1'">
            <v-btn depressed small dense @click="$emit('addRdv', demand)" color="primary" class="mr-2 mb-1">
                <v-icon small class="mr-2">$calendar_plus_d</v-icon>
                {{ $t('addrdv') }}
            </v-btn>
            <v-btn depressed small dense color="primary" @click="$emit('addPassage', demand)" class="mr-2 mb-1">
                <v-icon small class="mr-2">$handshake_d</v-icon>
                {{ $t('addpassage') }}
            </v-btn>
            <v-btn depressed small dense color="primary" @click="$emit('setReminder', demand)" class="mr-2 mb-1">
                <v-icon small class="mr-2">$alarm_d</v-icon>
                {{ $t('addremind') }}
            </v-btn>
        </div>
        <v-alert v-if="interactions.length == 0"  border="left" color="info" class="mt-2" text>
            {{ $t('noInteractions') }}
        </v-alert>
        <v-card v-for="(interaction, index) in interactions" class="mb-3" :key="'interaction_'+index">
            <v-card-title class="justify-space-between">
                <span class="d-flex align-center">
                    <h4>{{ $t(interaction.type) }}</h4>
                    <div v-if="interaction.type == 'REMIND'">
                        <v-chip :class="disabled ? 'ml-3 white--text' : 'ml-3'" small :color="disabled ? 'darkgrey' : 'warning'" v-if="interaction && interaction.start_date && (new Date(interaction.start_date) <= new Date())">{{$t('todeal')}}</v-chip>
                        <v-chip :class="disabled ? 'ml-3 white--text' : 'ml-3'" small :color="disabled ? 'darkgrey' : 'primary'" v-if="interaction && interaction.start_date && (new Date(interaction.start_date) > new Date())">{{$t('yettocome')}}</v-chip>
                    </div>
                    <div v-else>
                        <v-chip :class="disabled ? 'ml-3 white--text' : 'ml-3'" small :color="disabled ? 'darkgrey' : 'warning'" v-if="interaction && interaction.status && interaction.status == 'DRAFT'">{{$t('tobeconfirmed')}}</v-chip>
                        <v-chip :class="disabled ? 'ml-3 white--text' : 'ml-3'" small :color="disabled ? 'darkgrey' : 'primary'" v-if="interaction && interaction.status && interaction.status == 'CONFIRMED' && (new Date(interaction.date_start) > new Date())">{{$t('yettocome')}}</v-chip> 
                        <v-chip :class="disabled ? 'ml-3 white--text' : 'ml-3'" small :color="disabled ? 'darkgrey' : 'warning'" v-if="interaction && interaction.status && interaction.status == 'CONFIRMED' && (new Date(interaction.date_start) <= new Date())">{{$t('tobequalified')}}</v-chip> 
                        <v-chip :class="disabled ? 'ml-3 white--text' : 'ml-3'" small :color="disabled ? 'darkgrey' : 'success'" v-if="interaction && interaction.status && interaction.status == 'FINISHED' && interaction.is_success == '1'">{{$t('cameRdv')}}</v-chip> 
                        <v-chip :class="disabled ? 'ml-3 white--text' : 'ml-3'" small :color="disabled ? 'darkgrey' : 'error'" v-if="interaction && interaction.status && interaction.status == 'FINISHED' && interaction.is_success == '0'">{{$t('notComeRdvs')}}</v-chip> 
                    </div>
                </span>
                <div v-if="interaction.type == 'REMIND'">
                    <v-btn text depressed small class="square" @click="launchAction(interaction.id, transferRemindAction)"> <v-icon small color="primary">$switch_d</v-icon></v-btn>
                    <v-btn text depressed small class="square" @click="launchAction(interaction.id, editRemindAction)"> <v-icon small color="primary">$pencil_d</v-icon></v-btn>
                    <v-btn text depressed small class="square" @click="launchAction(interaction.id, deleteRemindAction)"> <v-icon small color="warning">$trash_d</v-icon></v-btn>
                </div>
                <div v-else-if="interaction.type != 'REMIND' && interaction.status != 'FINISHED'">
                    <v-btn text depressed small class="square" @click="editItem(interaction)"> <v-icon small color="primary">$pencil_d</v-icon></v-btn>
                    <v-btn text depressed small class="square" v-if="interaction.status == 'DRAFT'" @click="launchAction(interaction.id, deleteRdvAction)"> <v-icon small color="warning">$trash_d</v-icon></v-btn>
                    <v-btn text depressed small class="square" v-if="interaction.status == 'CONFIRMED'" @click="launchAction(interaction.id, cancelRdvAction)"> <v-icon small color="warning">$trash_d</v-icon></v-btn>
                </div>
            </v-card-title>
            <v-card-text>
                <ul class="pl-0" v-if="interaction.type == 'REMIND'">
                    <li v-if="interaction.start_date">
                        <v-icon x-small color="primary" class="mr-2">$clock_d</v-icon>
                        <span>
                            {{$t('dateandhour')}} : 
                            <v-chip outlined small color="purple">
                                {{ parseFullDateToFr(interaction.start_date) }}
                            </v-chip>
                        </span>
                    </li>
                    <li v-if="interaction.comment">
                        <v-icon x-small color="primary" class="mr-2">$commentaltlines_d</v-icon>
                        <span>{{ $t('comment') }} : {{ interaction.comment }}</span>
                    </li>
                    <v-btn depressed small color="pink" dark class="mt-2" @click="launchAction(interaction.id, confirmRemindAction)">{{ $t('markascompletedprogramming') }}</v-btn>
                </ul>
                <ul class="pl-0" v-else>
                    <li v-if="interaction.hasOwnProperty('visio')" class="d-flex align-center"><v-icon x-small color="primary" class="mr-2">$webcam_d</v-icon><span>{{$t('type')}} : {{ interaction.visio ? $t('presentiel') : $t('visio') }}</span></li>
                    <li v-if="interaction.date_start" class="d-flex align-center">
                        <v-icon x-small color="primary" class="mr-2">$clock_d</v-icon>
                        <span>
                            {{$t('dateandhour')}} : 
                            <v-chip outlined small color="purple">
                                {{ parseFullDateToFr(interaction.date_start) }}{{ interaction.date_end ? " / "+interaction.date_end.split(' ')[1].substr(0, (interaction.date_end.split(' ')[1]).length - 3) : "" }}
                            </v-chip>
                        </span>
                    </li>
                    <li v-if="interaction.date_start && interaction.date_end && interaction.type == 'RDV'" class="d-flex align-center">
                        <v-icon x-small color="primary" class="mr-2">$hourglass_half_d</v-icon>
                        <span>
                            {{$t('duration')}} : 
                            {{ parseFloatToHour(parseHourToFloat(interaction.date_end.split(" ")[1]) - parseHourToFloat(interaction.date_start.split(" ")[1])) }}
                        </span>
                    </li>
                    <li v-if="interaction.links_hydrated && interaction.links_hydrated.user && interaction.links_hydrated.user[0].title ">
                        <v-icon x-small color="primary" class="mr-2">$usertie_d</v-icon>
                        <span>
                            {{$t('vendor')}} : 
                            {{ interaction.links_hydrated.user[0].title  }}
                        </span>
                    </li>
                    <li v-if="interaction.place && interaction.place.config && interaction.place.config.name">
                        <v-icon x-small color="primary" class="mr-2">$map_marker_alt_s</v-icon>
                        <span>
                            {{$t('adress')}} : 
                            {{ interaction.place.config.name }} - {{ interaction.place.config.adress ? interaction.place.config.adress : "" }} {{ interaction.place.config.zip ? interaction.place.config.zip : "" }} {{ interaction.place.config.city ? interaction.place.config.city : "" }}
                        </span>
                    </li>
                    <li v-if="interaction.accompagnants && interaction.accompagnants != '0'">
                        <v-icon x-small color="primary" class="mr-2">$user_plus_d</v-icon>
                        <span>
                            {{$t('accompagnants')}} : 
                            {{ interaction.accompagnants }}
                        </span>
                    </li>
                    <li v-if="interaction.comment && interaction.comment != ''">
                        <v-icon x-small color="primary" class="mr-2">$commentaltlines_d</v-icon>
                        <span>
                            {{$t('comment')}} : 
                            {{ interaction.comment }}
                        </span>
                    </li>
                </ul>
                <div class="d-flex justify-end">
                    <v-btn v-if="interaction.status == 'DRAFT'" color="pink" dark depressed small class="mt-2" @click="launchAction(interaction.id, confirmRdvAction)">{{ $t('confirm') }}</v-btn>
                    <v-btn v-if="interaction.status == 'CONFIRMED'" color="pink" dark depressed small class="mt-2" @click="launchAction(interaction.id, qualifRdvAction)">{{ $t('qualif') }}</v-btn>
                </div>
            </v-card-text>
        </v-card>
        <v-dialog v-model="qualifItem" width="600">
            <v-card color="lightgrey">
                <v-toolbar tile flat dark  class="bg-gradient" style="max-height:64px;">
                    <v-toolbar-title v-if="object.type == 'PASSAGE'">{{object.type}} - {{parseFullDateToFr(object.date_start)}} / {{parseFullDateToFr(object.date_end)}} </v-toolbar-title>
                    <v-toolbar-title v-else>{{object.link}} - {{object.title}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-text class="pt-5 px-12" style="position:relative;">
                    <p v-if="object && object.start_date"><b>{{$t('start_date')}} : </b><span>{{parseFullDateToFr(object.start_date)}}</span></p>
                    <p v-if="object && object.date_start"><b>{{$t('start_date')}} : </b><span>{{parseFullDateToFr(object.date_start)}}</span></p>
                    <p v-if="object && object.date_end"><b>{{$t('end_date')}} : </b><span>{{parseFullDateToFr(object.date_end)}}</span></p>
                    <p><b>{{$t('comment')}} : </b> <span>{{object.comment}}</span></p>
                    <span v-if="object.type == 'PASSAGE' && object.status == 'FINISHED'">
                        <v-btn-toggle mandatory  color="primary" background-color="lightgrey" v-model="object.is_success" class="mr-2 " @click.stop="">
                            <v-btn outlined small @click.stop="setSuccess(1, object)" :value="'1'" style=" border-radius: 5px 0 0 5px !important;">
                                <span>{{$t('passageCame')}}</span>
                            </v-btn>
                            <v-btn outlined small @click.stop="setSuccess(0, object)" :value="'0'" style="border-radius: 0 5px 5px 0 !important;">
                                <span>{{$t('passageNotCame')}}</span>
                            </v-btn>
                        </v-btn-toggle>
                    </span>
                    <v-list color="lightgrey">
                        <v-list-item v-if="object.type == 'PASSAGE' && object.status != 'FINISHED'" style="border-radius:5px;" class="listItemButtons" link @click="">
                            <v-icon color="primary" class="mr-3">$pencil_d</v-icon>
                            {{$t('edit')}}
                        </v-list-item>
                        <v-list-item v-else-if="object.type == 'TODO'" style="border-radius:5px;" class="listItemButtons" link @click="getForm(todoEditFormUrl+object.id)">
                            <v-icon color="primary" class="mr-3">$pencil_d</v-icon>
                            {{$t('edit')}}
                        </v-list-item>
                        <v-list-item style="border-radius:5px;" class="listItemButtons" link @click="launchAction(index, button)" v-for="(button, index) in buttons" :key="'buttons_'+index">
                            <v-icon color="primary" class="mr-3">{{button.icon}}</v-icon>
                            {{$t(index)}}
                        </v-list-item>
                    </v-list>
                    
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog class="rounded-lg" persistent width="600" v-model="confirmDialog">
            <v-card>
                <v-toolbar tile flat dark  class="bg-gradient" >
                    <div style="display:flex; width:100%; align-items:center; justify-content:space-between;">
                        <div>
                            <v-toolbar-title>{{$t(confirmTitle)}}</v-toolbar-title>
                        </div>
                        <div>
                            <v-btn outlined dark depressed @click.native="closeConfirm">{{$t('cancel')}}</v-btn>
                            <v-btn outlined dark depressed @click="confirmAction" class="ml-3">{{$t('confirm')}}</v-btn>
                        </div>
                    </div> 
                </v-toolbar>
                <v-card-text class="mt-4">
                    {{$t(confirmText)}}
                </v-card-text>
            </v-card>
        </v-dialog>
        <FormDialogComponent
        v-if="form"
        ref="componentForm"
        :form="form"
        :action="'SET_DATA'"
        />
    </div>
</template>
<script>
import GenericDataService from '@/services/GenericDataService'
import dateManipulation from '@/mixins/dateManipulation'
import FormDialogComponent from '@/components/dialogs/FormDialogComponent'
import getForm from "@/mixins/mixins";

export default {
    name:"clientInteractionComponent",
    props: ['objects', 'disabled', 'demand'],
    components:{FormDialogComponent},
    mixins:[dateManipulation, getForm],
    data() {
        return {
            interactions:[],
            object:{},
            buttons:[],
            excerpt: {},
            qualifItem: false,
            form:null,
            confirmDialog: false,
            confirmTitle: "",
            confirmText: "",
            deleteRdvAction: {
                "icon": "$trash_d",
                "confirm": {
                    "title": "deleteRdvTitle",
                    "text": "deleteRdvText"
                },
                "action": "post",
                "postUrl": "\/rdv\/{{id}}\/deleteRdv"
            },
            cancelRdvAction: {
                "confirm": {
                    "title": "cancelRdvTitle",
                    "text": "cancelRdvText"
                },
                "action": "post",
                "postUrl": "\/rdv\/{{id}}\/cancelRdv"
            },
            confirmRdvAction: {
                "confirm": {
                    "title": "confirmRdvTitle",
                    "text": "confirmRdvText"
                },
                "action": "post",
                "postUrl": "\/rdv\/{{id}}\/confirmRdv"
            },
            qualifRdvAction: {
                "action": "form",
                "formUrl": "\/rdv\/{{id}}\/getForm?formName=FEEDBACK_RDV"
            },
            editRemindAction: {
                "action": "form",
                "formUrl": "\/todo\/editForm?id={{id}}"
            },
            deleteRemindAction: {
                "confirm": {
                    "title": "deleteRemindTitle",
                    "text": "deleteRemindText"
                },
                "action": "post",
                "postUrl": "\/todo\/{{id}}\/remindDelete"
            },
            transferRemindAction: {
                "action": "form",
                "formUrl": "\/todo\/{{id}}\/transfertForm"
            },
            confirmRemindAction: {
                "confirm": {
                    "title": "confirmRemindTitle",
                    "text": "confirmRemindText"
                },
                "action": "post",
                "postUrl": "\/todo\/{{id}}\/remindConfirm"
            },
            actionToConfirm: {},
        }
    },
    created() {
        if(this.objects && this.objects.length > 0 && this.interactions.length == 0){
            this.objects.forEach(element => {
                if(element.type == 'RDV' || element.type == 'PASSAGE'){
                    GenericDataService.getData('/rdv/'+element.id+'/get').then((response) => {
                        this.interactions.push(response.data.data)
                    })
                }
                if(element.type == "TODO"){
                    GenericDataService.getData('/todo/getOne?id='+element.id).then((response) => {
                        this.interactions.push(response.data.data[0])
                    })
                }
            })
            this.interactions.sort((a, b) => ((a.date_start ? a.date_start : a.start_date) > (b.date_start ? b.date_start : b.start_date)) ? 1 : -1)
        }
    },
    methods: {
        
        editItem(item){
            if(item.type == 'RDV'){
                GenericDataService.getData('/rdv/'+item.id+"/get").then((response) => {
                    this.$emit('editRdv', response.data.data) 
                })
            } else if (item.type == 'REMIND'){
                    this.object = item
                    GenericDataService.getData("todo/getButtonList?todo_id="+item.id).then((response) => { 
                        this.buttons = response.data.data
                        GenericDataService.getData('/todo/getExcerptByTodo?todo_id=' + item.id).then((response) => {
                            this.excerpt = response.data.data
                            this.qualifItem = true
                        });
                    })
                
                
                //this.$emit('editTodo', this.todoEditFormUrl+item.id) 
            } else if (item.type == 'PASSAGE') {
                GenericDataService.getData('/rdv/'+item.id+"/get").then((response) => {
                    this.object = response.data.data
                    this.buttons = []
                    //this.qualifItem = true
                    this.$emit('editPassage', response.data.data)
                })                
            }
        },
        customRefresh(response){
            this.$emit('editTodo') 
            this.closeModal()
        },
        closeModal(){
            this.object = {}
            this.buttons = {}
            this.excerpt = {}
            this.qualifItem = false
        },
        confirmAction(){
            if(this.actionToConfirm.action == "post"){
                let url = this.actionToConfirm.postUrl
                GenericDataService.postData(url).then((response) => {
                    this.confirmDialog = false
                    this.customRefresh(response)
                })
            } 
        },
        closeConfirm(){
            this.confirmDialog = false
            this.confirmTitle = ""
            this.confirmText = ""
            this.actionToConfirm = {}
        },
        launchAction(index, action){
            if(action.confirm && action.confirm.hasOwnProperty('title')){
                this.confirmDialog = true
                this.confirmTitle = action.confirm.title
                this.confirmText = action.confirm.text
                action.postUrl = action.postUrl.replace('{{id}}', index)
                this.actionToConfirm = action
            } else {
                if (action.action == "form"){
                    let url = action.formUrl.replace('{{id}}', index)
                    this.getForm(url)
                } 
            }
        },
    },
}
</script>
<style lang="scss">
    .interactionClient {
        h1 {
            font-size:24px;
            line-height: 24px;
        }
        h4 {
            font-size:16px !important;
            padding-bottom:0 !important;
        }
        ul {
            list-style:none;
            li{
                font-size:12px;
            }
        }
       
            .v-btn__content {
                font-size:11px !important;
            }
            
        
    }
</style>