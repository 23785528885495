<template>
  <v-card>
    <v-card-title>
      <h2 class="icon-title mb-0" style="width:100%; align-items:center;">
        {{ $t("contactItems") }}
        <v-menu v-if="$func.hasRight('lead/update')" offset-y left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" small depressed class="square" color="primary">
              <v-icon dark small> $plus_l </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(item, key) in itemsAvailable" :key="key" link dense @click="callForm('create', formItemUrl + item.type)">
              <v-list-item-title>{{ $t(item.name) }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </h2>
    </v-card-title>
    <v-expansion-panels tile v-if="items && items.length" accordion v-model="itemPanel">
      <div v-for="(item, index) in items" :key="index" style="width: 100%;">
        <productComponent v-model="itemPanel" @editForm="callForm('edit', formItemUrl + item.type.value + '&id=' + item.id.value)"  @deleteForm="callForm('delete', deleteItemUrl + item.id.value)" :item="item" :index="index"/>
      </div>
    </v-expansion-panels>
    <div class="pb-1" v-else>
      <v-alert class="mt-2 mx-4" border="left" color="info" text>
        {{ $t('emptyItemArrayInfoMessage') }}
      </v-alert>
    </div>

    <FormDialogComponent
      v-if="form"
      ref="componentForm"
      :type="formType"
      :form="form"
      :action="'SET_DATA'"
    />
  </v-card>
</template>

<script>
import GenericDataService from '@/services/GenericDataService';
import FormDialogComponent from "@/components/dialogs/FormDialogComponent";
import productComponent from '@/modules/contacts/contactsComponents/productComponent'
import getForm from "@/mixins/mixins";
import refreshData from "@/mixins/mixins";

export default {
  name: "ContactDetailItems",
  components: {
    FormDialogComponent,
    productComponent
  },
  mixins: [getForm, refreshData],
  data() {
    return {
      itemPanel: 0,
      form: null,
      formUrl: "",
      formType: "",
      apiUrl: "/contact/" + this.$route.params.contact_id + "/getContactItems",
      mutation: "contact/setItemsData",
      formItemUrl: "/contact/" + this.$route.params.contact_id + "/getItemForm?type=",
      deleteItemUrl: "/contact/" + this.$route.params.contact_id + "/checkBeforeDeleteItem?id=",
      itemsAvailable: [],
    };
  },
  created() {
    this.refreshData();

    GenericDataService.getData('/hard_value/getValuesByType?type=items').then((response) => {
      let tmpItems = response.data.data;

      for(const item in tmpItems) {
        tmpItems[item].type = item;
        this.itemsAvailable.push(tmpItems[item]);
      }
    });
  },
  watch: {
    $route(to, from) {
      this.formItemUrl = "/contact/" + this.$route.params.contact_id + "/getItemForm?type=";
      this.deleteItemUrl = "/contact/" + this.$route.params.contact_id + "/checkBeforeDeleteItem?id=";
      this.apiUrl = "/contact/" + this.$route.params.contact_id + "/getContactItems";
      this.refreshData();
    },
  },
  methods: {
    callForm(formType, formUrl) {
      this.formType = formType;
      this.formUrl = formUrl;
      this.getForm(formUrl);
    },
  },
  computed: {
    items: function () {
      return this.$store.state.contact.items;
    },
  },
};
</script>

<style lang="scss">
</style>
