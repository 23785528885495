<template>
      <div> 
        <div>
            <span v-for="(leadInSection, leadSection) in leads">
                <span class="d-flex justify-space-between align-start lightgrey pa-4 py-2" style="width:100%;">
                    <h2 class="pink--text d-flex align-center mb-0" style="text-transform: none;">
                        {{$t(leadSection) }}
                        <v-avatar size="20" :color="'primary'" class="white--text ml-4">{{leadInSection.length}}</v-avatar>
                    </h2> 
                </span>
                <div>
                    <div v-for="(lead,i) in leadInSection" :key="'lead_'+lead.id" @click="openSuperlead(lead)" style="cursor:pointer;">
                        <div class=" d-flex justify-space-between my-4 px-4">
                            <div>
                                <div class="h7" style="font-weight:700 !important;">{{ parseUsDateToFr(lead.created_at.split(' ')[0]) }} : {{ lead.operation_id.label }}</div>
                                <ul>
                                    <li v-for="(demand, index) in lead.demands" :key="demand.id" style="font-size: 15px;">
                                        {{ $t(demand.type) }} : {{ demand.name }}
                                    </li>
                                </ul>
                            </div>
                            <div class="d-flex align-center justify-space-between testActions">
                                <div class="rdvCaps mr-2" v-if="lead.rdvs && lead.rdvs.id">
                                    <v-icon :color="leadSection == 'todeal' ? 'warning' : (leadSection == 'waiting' ? 'primary' : (lead.custom_status == 'SUCCESS' ? 'success' : 'black') )">
                                        {{ lead.rdvs.type == 'RDV' ? '$calendar_l' : '$runner_s' }}
                                    </v-icon>
                                    <span class="ml-2">{{  parseFullDateToFr(lead.rdvs.date_start) }}</span>
                                </div>
                                <div class="rdvCaps mr-2" v-else>
                                    <v-icon :color="leadSection == 'todeal' ? 'warning' : (leadSection == 'waiting' ? 'primary' : (lead.custom_status == 'SUCCESS' ? 'success' : 'black') )">
                                        {{ '$calendar_l' }}
                                    </v-icon>
                                    <span class="ml-2">{{  $t('nordv') }}</span>
                                </div>
                                <div v-if="lead.affected_user && lead.affected_user.id" class="mr-3 leadUser">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-avatar size="30" v-if="lead.affected_user.id" v-bind="attrs" v-on="on" class="mr-2">
                                                <v-img
                                                :src="
                                                    api +
                                                    '/user/' +
                                                    lead.affected_user.id +
                                                    '/getAvatar?token=' +
                                                    $store.state.auth.idToken
                                                "
                                                ></v-img>
                                            </v-avatar>
                                        </template>
                                        <span class="white--text">{{ lead.affected_user.firstname + " "+ lead.affected_user.name  }}</span>
                                    </v-tooltip>
                                </div>
                                <div v-else>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-avatar color="mediumgrey" size="30" v-bind="attrs" v-on="on" class="mr-2">
                                            
                                                <v-icon small>$usertie_d</v-icon>
                                            </v-avatar>
                                        </template>
                                        <span class="white--text">{{ $t('notaffected') }}</span>
                                    </v-tooltip>
                                </div>
                                <div class="mr-2 leadStatus" >
                                    <v-tooltip top v-if="lead.detail_status && lead.detail_status != ''">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-chip small :color="colors[lead.custom_status]" v-bind="attrs" v-on="on" dark>
                                                {{ $t(lead.custom_status) }} <span class="ml-1" v-if="lead.detail_status"></span>
                                            </v-chip>
                                        </template>
                                        <span class="white--text">{{ lead.custom_status == 'FAIL' ? lead.detail_status : $t(lead.detail_status) }}</span>
                                    </v-tooltip>
                                    <v-chip small v-else :color="colors[lead.custom_status]" dark>
                                        {{ $t(lead.custom_status) }} 
                                    </v-chip>
                                </div>
                                <v-icon small>$chevroncircleright_d</v-icon>
                            </div>
                        </div>
                        <v-divider></v-divider>
                    </div>
                </div>
            </span>
        
    </div>

        <FormDialogComponent v-if="form" ref="componentForm" :type="formType" :form="form" :action="'SET_DATA'" />
        <ConfirmDialogComponent ref="confirmDelete" />
    </div>
</template>

<script>
import GenericDataService from '@/services/GenericDataService'
import historyDemandComponent from '@/modules/contacts/contactsComponents/historyDemandComponent'
import contentContactDemandComponent from '@/modules/contacts/contactsComponents/contentContactDemandComponent'
import clientInteractionComponent from '@/modules/contacts/contactsComponents/clientInteractionComponent'
import objectsDemandComponent from '@/modules/contacts/contactsComponents/objectsDemandComponent'
import FormDialogComponent from "@/components/dialogs/FormDialogComponent";
import getForm from "@/mixins/mixins";
import refreshData from "@/mixins/mixins";
import displayDatatableAlert from "@/mixins/mixins";
import validate from '../../../components/forms/functions/validations'
import ConfirmDialogComponent from '@/components/dialogs/ConfirmDialogComponent';
import dateManipulation from '@/mixins/dateManipulation';

export default {
    name: "demandsComponent",
    components: {historyDemandComponent, contentContactDemandComponent, FormDialogComponent, objectsDemandComponent, ConfirmDialogComponent, clientInteractionComponent},
    mixins:[getForm, refreshData, validate, displayDatatableAlert, dateManipulation], 
    props: ['contact'],
    data() {
        return {
            demands:[],
            registrations:[],
            canAddRegistration:false,
            requestFormUrl: "/demand/{{id}}/getForm?formName=",
            requestsFormUrl: "/demand/_getFormNew?contact_id=" + this.$route.params.contact_id + "&type=",
            refistrationsFormUrl: "/contact/getFormRegistrations?contact_id=" + this.$route.params.contact_id,
            registrationFormUrl: "/contact/getFormRegistrations?contact_id=" + this.$route.params.contact_id + "&id={{id}}",
            registrationQualifyUrl: "/rdv/{{id}}/getForm?formName=FEEDBACK_RDV",
            registrationDeleteLink: {
                action: "delete",
                confirm: {
                    "label": "registrationDeleteConfirmTitle",
                    "message": "registrationDeleteConfirmMessage"
                },
                url: "/rdv/cancelRdv?id={{id}}"
            },
            form: null,
            formUrl: "",
            formType: "",
            demandAction:{},
            operationAction:{},
            dialogDemandGestion: false, 
            transferDemandDialog: false,
            addPassageBtn: false,
            transferDemandUser: null, 
            transfertDialogItems: [],
            reminderFormUrl: "/todo/newForm?demand_id={{id}}&only_remind=true",
            offerSentFormUrl: "/todo/newForm?demand_id={{id}}&only_offer_sent=true",
            todeal:false,
            demandTypes:[],
            interactionKey:0,
            detailKey:0,
            transferId:0,
            api: process.env.VUE_APP_API_URL,
            selectedDemand:{},
            colors: {
                "TO_DEAL": "state_todo",
                "SUFFERING": "state_suffer",
                "WAITING": "state_before",
                "SUCCESS": "state_success",
                "FAIL": "state_fail"
            },
            leads : []
        }
    },
    created() {
        this.loadDemands();
    },
    watch: {
        $route(to, from) {
            this.requestsFormUrl = "/demand/_getFormNew?contact_id=" + this.$route.params.contact_id + "&type=";
            this.loadDemands();
        },
    },
    methods: {
        openSuperlead(lead){
            GenericDataService.getData('superlead/get?id=' + lead.id ).then((response)=> {
                this.refreshInteractions();
                this.$store.dispatch('lead/SET_SUPERLEAD_AND_OPEN_DIALOG', response.data.data)
            });
        },
        openDemand(demand){
            GenericDataService.getData('superlead/getByDemandId?id=' + demand.id + ('&operation_id=' + (demand.operation_id.id ? demand.operation_id.id : demand.operation_id))).then((response)=> {
                this.refreshInteractions();
                this.$store.dispatch('lead/SET_SUPERLEAD_AND_OPEN_DIALOG', response.data.data)
            });
        },
        refreshInteractions(){
            GenericDataService.getData('/contact/' + this.$route.params.contact_id + '/getDemandsWithDetails').then((response) => {
                this.demands = response.data.data;
                this.interactionKey ++
            });
        },
        loadDemands() {
            GenericDataService.getData('/contact/' + this.$route.params.contact_id + '/getDemandsWithDetails').then((response) => {
                this.demands = response.data.data;
            });
            if(this.$func.hasRight('lead/get'))
                GenericDataService.getData('/contact/getSuperleadList?contact_id='+ this.$route.params.contact_id).then((response) => {
                    this.leads = response.data.data;
                })
            // GenericDataService.getData('/contact/' + this.$route.params.contact_id + '/getRegistrationsWithDetails').then((response) => {
            //     this.registrations = response.data.data.registrations;
            //     this.canAddRegistration = response.data.data.canAddRegistration;
            // });
            GenericDataService.getData('/hard_value/getValuesByType?type=demand_type').then((response) => {
              let tmpTypes = response.data.data;

              for(const type in tmpTypes) {
                tmpTypes[type].type = type;
                this.demandTypes.push(tmpTypes[type]);
              }
            });
        },
        callForm(formType, formUrl, ids = null) {
            this.formType = formType;
            this.formUrl = formUrl;
            if(formType === "recordSuccess") {
                GenericDataService.postData(formUrl).then((response) => {
                    if(response.data.success) {
                        this.displayDatatableAlert(response);
                    }
                });
            } else {
                this.getForm(formUrl, ids);
            }
        },
        customRefresh(){
            GenericDataService.getData('/contact/' + this.$route.params.contact_id + '/getDemandsWithDetails').then((response) => {
                this.demands = response.data.data;
                this.refreshInteractions()
                this.detailKey ++
            });
            // GenericDataService.getData('/contact/' + this.$route.params.contact_id + '/getRegistrationsWithDetails').then((response) => {
            //     this.registrations = response.data.data.registrations;
            //     this.canAddRegistration = response.data.data.canAddRegistration;
            // });
            this.$emit('refreshTodos');
        }        
    },
    computed: {
        user(){
            return this.$store.state.auth.user;
        },
        entityVisuParam(){
            if(this.$store.state.auth.currentUser.accountParameters.entity_visu)
                return this.$store.state.auth.currentUser.accountParameters.entity_visu;
            else    
                return "readonly"
        },
        entityAvailable(){
            return this.$store.state.auth.currentUser.rights[0].entities;
        },
        activeDemands(){
            let orderedDemands = {
                todeal:{},
                waiting:{}
            }
            //a traiter
            if(this.entityVisuParam == 'none')
                orderedDemands.todeal.demands = this.demands.filter((e) => e.state == 'ON_GOING' && this.entityAvailable.includes(e.entity_id))
            else
                orderedDemands.todeal.demands =  this.demands.filter((e) => e.state == 'ON_GOING')
            
            orderedDemands.todeal.registrations = this.registrations.filter((e) => e.status == 'DRAFT' || (e.status == 'CONFIRMED' && (new Date(e.date_start) <= new Date())))
            
            //En Attente
            if(this.entityVisuParam == 'none')
                orderedDemands.waiting.demands =  this.demands.filter((e) => e.state == 'WAITING' && this.entityAvailable.includes(e.entity_id))
            else
                orderedDemands.waiting.demands = this.demands.filter((e) => e.state == 'WAITING')

            orderedDemands.waiting.registrations = this.registrations.filter((e) => e.status == 'CONFIRMED' && (new Date(e.date_start) > new Date()))

            return orderedDemands
        },
        closedDemands(){
            if(this.entityVisuParam == 'none')
                return this.demands.filter((e) => e.state == 'CLOSED' && this.entityAvailable.includes(e.entity_id))
            else 
                return this.demands.filter((e) => e.state == 'CLOSED')  
        },
        closedRegistrations(){
                return this.registrations.filter((e) => e.status == 'FINISHED')

        },
    },
}
</script>

<style lang="scss">
.btnContact {
    .v-btn__content {
        font-size:12px !important;
    }
    &.disabled {
        background-color: #CCC !important;
        cursor:default;
    }
}
.showDemandBtn {
    cursor:pointer;
    text-decoration: underline;
    font-weight: normal;
    &:hover{
        font-weight: bold;
    }
}
    .panelDemand {
        border-radius:5px !important;
        h4 {
            border-bottom: 0px !important;
            text-transform: none;
            font-size:20px;
        }
        .icon {
            transition: transform 0.2s ease-in;
            &.rotate{
                transform:rotate(180deg);
            }
        }
        .panelDemandContent {
            h2 {
                text-transform: none;
                min-height:unset !important;
            }
        }
    }
    .iconTodo {
        //border: 2px solid var(--v-primary-base) !important;
    }
    .testActions {
        width:375px !important;
        flex-shrink:0;
        flex-grow:0;
    }
    .rdvCaps{
        width:170px;
        text-align: left;
        flex-shrink:0;
        flex-grow:0;
    }
    .leadUser{ 
        width:30px;
        flex-shrink:0;
        flex-grow:0;
     }
    .leadStatus {
        width:130px;
        flex-shrink:0;
        flex-grow:0;
    }
    .rdvCaps {
        background-color:var(--v-lightgrey-base);
        padding:10px;
        display:flex;
        align-items: center;
        color:black;
        border-radius:5px;
    }
</style>