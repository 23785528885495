<template>
    <v-card class="no-shadow pa-3 contentContactDemandDetail" color="extralightgrey">
        <v-card-title class="d-flex flex-column align-start">
                <div class="d-flex justify-space-between align-center descriptionTitle">
                    <h1 class="icon-title">Description</h1>
                    <v-btn small color="primary" depressed @click="$emit('editForm')" v-if="!disabled && demand.is_closed != '1'"><span class="modifyBtn">{{ $t('modify') }}</span> <v-icon x-small class="ml-1">$next</v-icon></v-btn>
                </div>
                 
                <div>
                    <p v-if="demand && demand.details && demand.details.comment">{{ demand.details.comment }}</p>
                </div>
        </v-card-title>
        <v-card-text class="pt-2">
            <div  class="mt-0">
                <h2>{{$t('details')}}</h2>
                <ul class="mt-3 pl-0" style="list-style:none;" v-if="demand.operation_name || demand.created_at || affected_user || demand.id">
                    <li v-if="demand.operation_name" class="mt-2" style="position:relative;">
                        <p class="mb-0 d-inline"><b>{{$t('operation')}} :</b></p>
                        <p class="mb-0 ml-2 d-inline">
                            <span :class="disabled ? '' : 'link'" @click="disabled ? null : openDrawerObject(demand.operation_id)">{{demand.operation_name}}</span>
                            <!-- <router-link style="text-decoration:underline;" :to="{name:'OperationDetail', params:{operation_id: demand.operation_id}}">{{demand.operation_name}}</router-link> -->
                        </p>
                    </li>
                    <li v-if="demand.created_at" style="position:relative;">
                        <p class="mb-0 d-inline"><b>{{$t('created_at')}} :</b> <span class="black--text">{{parseUsDateToFr(demand.created_at.split(' ')[0]) }} {{ (demand.created_at.split(' ')[1]).substr(0, (demand.created_at.split(' ')[1]).length - 3) }}</span></p>
                    </li>
                    <li v-if="affected_user && affected_user.hasOwnProperty('id')" style="position:relative;">
                        <p class="mb-0 d-inline"><b>{{$t('owner')}} :</b> <span class="black--text">{{ affected_user.firstname }} {{ affected_user.name }}</span></p>
                    </li>
                    <li v-if="demand.id" style="position:relative;">
                        <p class="mb-0 d-inline"><b>{{$t('reference')}} :</b> <span class="black--text">#D{{ demand.id}}</span></p>
                    </li>
                </ul>  
                <v-alert class="mb-0 mb-2" border="left" color="info" v-else text>
                    {{ $t("noData") }}
                </v-alert>
            </div>
            <div class="mt-6" v-if="(demand.criteria && demand.criteria.length) || (demand.details && demand.details.length) || (demand.amount && demand.amount != '' && demand.amount != '0') || demand.item || demand.univers_name">
                <h2 class="">{{$t('criteria')}}</h2>
                <ul class="mt-3" style="list-style:none;" v-if="demand.type == 'sell' && demand.criteria && demand.criteria.length">
                    <li v-for="(criteria, index) in demand.criteria" :key="index" class="mt-2" style="position:relative;">
                        <p class="mb-0 d-inline"><v-icon x-small color="grey" style="position:absolute; left:-23px; top:3px;">$circle_s</v-icon><b>{{criteria.reference}} :</b></p>
                        <p class="mb-0 ml-2 d-inline" v-if="criteria.value.text">{{criteria.value.text}}</p>
                        <p class="mb-0 ml-2 d-inline" v-else>{{criteria.value}}</p>
                    </li>
                </ul>
                <ul class=" pl-0" style="list-style:none;">
                    <li v-for="(detail, index) in demand.details" v-if="index !== 'comment'" :key="index" class="mt-2" style="position:relative;">
                        <p class="mb-0 d-inline"><b>{{$t(index)}} :</b></p>
                        <p class="mb-0 ml-2 d-inline black--text" v-if="index === 'fundingEndDate'">{{parseUsDateToFr(detail)}}</p>
                        <p class="mb-0 ml-2 d-inline black--text" v-else-if="index === 'interests'">{{detail.map(e => e.text).join(", ")}}</p>
                        <p class="mb-0 ml-2 d-inline black--text" v-else>{{detail}}</p>
                    </li>
                    <li v-if="demand.amount && demand.amount != '' && demand.amount != '0'" style="position:relative;">
                        <p class="mb-0 d-inline"><b>{{$t('amount')}} :</b> <span class="black--text">{{demand.amount}} €</span></p>
                    </li>
                    <li v-if="demand.item" style="position:relative;">
                        <p class="mb-0 d-inline"><b>{{$t('item')}} :</b> <span class="black--text">{{demand.item.label}} </span></p>
                    </li>
                    <li v-if="demand.univers_name" style="position:relative;">
                        <p class="mb-0 d-inline"><b>{{$t('univers')}} :</b> <span class="black--text">{{demand.univers_name}}</span></p>
                    </li>
                </ul>
            </div>
            <div v-if="demand.type == 'sell' && demand.hasStock && demand.is_closed != '1'" class=" mt-6">
                <h2 class=" mb-0">{{$t('clientInterest')}} </h2>
                <div class="mt-2">
                    <vCustomLibrarySelect :model="demand" fieldName="stock_selection" :field="field_stock" />
                </div>
            </div> 
        </v-card-text>
    </v-card>
</template>
<script>
import dateManipulation from '@/mixins/dateManipulation';
import GenericDataService from '@/services/GenericDataService';
import vCustomLibrarySelect from '../../../components/forms/inputs/v-custom-library-select.vue';
export default {
    name:"contentContactDemandComponent",
    mixins: [dateManipulation],
    props: ["demand", "disabled"],
    components:{vCustomLibrarySelect},
    data() {
        return {
            affected_user: {},
            field_stock: {
                component: "VCustomLibrarySelect",
                props: {
                    type: "car",
                    multiple: true
                }
            }
        }
    },
    watch: {
        'demand.stock_selection'(newVal) {
            GenericDataService.postData('/demand/'+this.demand.id+'/_set', this._.cloneDeep(this.demand)).then((response) => {
            })
        }
    },
    created() {
        if(this.demand && this.demand.affected_user){
            GenericDataService.getData('/user/'+this.demand.affected_user+'/get').then((response) => {
                this.affected_user = response.data.data
            })
        }
    },
    methods: {
        openDrawerObject(id){
            GenericDataService.getData('/operation/' + id + '/getExcerpt').then((response) => {
                this.$store.dispatch("auth/SET_OPEN_OPERATION_DRAWER", { value: !this.$store.state.auth.openOperationDrawer, operation: id, excerpt : response.data.data});
            });
        },
    }
}
</script>
<style lang="scss">
    .contentContactDemandDetail {
        .descriptionTitle {
            width:100%;
        }
        .modifyBtn {
            font-size:13px;
        }
        h1 {
            font-size:24px;
            line-height: 24px;
        }
        h2{
            text-transform: none;
            min-height:unset;
        }
    }
</style>