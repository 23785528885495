<template>
    <v-card class="todoContactComponent">
        <v-card-title class="justify-center">
            <h2>{{ $t('othertasktodo') }}</h2>
        </v-card-title>
        <v-card-text>
            <div v-if="todos.length > 0">
                <div class="todoCard mb-3 pa-3"  v-for="(todo, index) in todos" @click="openTodoObject(todo)" :key="'todo_'+todo.id">
                    <h6 class="pb-0">{{$t(todo.type)}} <span v-if="todo.type == 'LEAD_TO_DEAL' && todo.link_hydrated && todo.link_hydrated.title"> : {{  todo.link_hydrated.title}}</span></h6>
                    <div class="mt-2" v-if="todo.objective">
                        <b>{{ $t('retroplanningconfig.objectivetitle') }} : </b> {{ todo.objective }}
                    </div>
                    <div class="mt-2">
                        <b>{{ $t('affected_user') }} : </b> 
                        <v-avatar size="30" class="mr-2">
                            <v-img :src="(todo.user_link.params && todo.user_link.params.user_id >= 0 ? api+'/user/'+todo.user_link.params.user_id+'/getAvatar?token='+$store.state.auth.idToken : api+'/user/getAvatar?id=-1&token='+$store.state.auth.idToken)"></v-img>
                        </v-avatar>
                        {{ todo.user_link.title }}
                    </div>
                    <div class="mt-2">
                        <b>{{ $t('delay') }} : </b> 
                        <span :class="todo.due_in_seconds.color+'--text'">
                            <strong>{{ getChipsTime(todo.due_in_seconds.value) }}</strong><i class="ml-1">{{ todo.due_in_seconds.value >= 0 ? $t('before_deadline') : $t('of_delay') }}</i>
                        </span>
                    </div>
                </div>
            </div>
            <v-alert v-else-if="todos.length == 0" border="left" text dense color="info">
                {{ $t('noTodoLabel') }}
            </v-alert>
        </v-card-text>
    </v-card>
</template>
<script>
import GenericDataService from '@/services/GenericDataService'
import getChipsTime from '@/mixins/dateManipulation';
export default {
    name:"contactTodoComponent",
    mixins:[getChipsTime],
    data() {
        return {
            todos:[],
            api: process.env.VUE_APP_API_URL
        }
    },
    created() {
        this.initTodos()
    },
    watch: {
        $route(to, from) {
            if(this.$route.params.contact_id){
                GenericDataService.getData('/todo/getContactTodoList?contact_id='+this.$route.params.contact_id).then((response) => {
                    this.todos = response.data.data
                })
            }
        },
        todo: {
            deep:true,
            handler(val){
                if(!val.hasOwnProperty('id')){
                    setTimeout(() => {
                        this.$store.dispatch("contact/REFRESH_CONTACTDETAIL_TODO", true);
                    }, 100);
                }   
            }
            
        }
    },
    computed: {
        todo(){
            return this.$store.state.auth.todoForDrawer
        },
    },
    methods: {
        initTodos(){
            if(this.$route.params.contact_id){
                GenericDataService.getData('/todo/getContactTodoList?contact_id='+this.$route.params.contact_id).then((response) => {
                    this.todos = response.data.data
                })
            }
        },
        openTodoObject(object){
            // Si c'est un Lead à traiter alors on ouvre directement le superlead
            if(["LEAD_TO_DEAL", "RDV_TO_CONFIRM", "FEEDBACK_RDV", "FEEDBACK_PASSAGE", "FEEDBACK_INSCRIPTION"].includes(object.type)) {
                let url = "superlead/getByRdvId?id=" + object.module_id;
                if(object.module === 'demand')
                    url = "superlead/getByDemandId?id=" + object.module_id;

                GenericDataService.getData(url + ('&operation_id=' + (object.operation_id.id ? object.operation_id.id : object.operation_id))).then((response)=> {
                    this.$store.dispatch('lead/SET_SUPERLEAD_AND_OPEN_DIALOG', response.data.data)
                });
            } else {
                GenericDataService.getData('/todo/getExcerptByTodo?todo_id=' + object.id).then((response) => {
                    this.$store.dispatch("auth/SET_OPEN_TODO_ACTION_DRAWER", { value: !this.$store.state.auth.openTodoActionDrawer, todo: object, excerpt : response.data.data});
                });   
            }
        }
    },
}
</script>
<style lang="scss">
    .todoContactComponent {
        h2 {
            text-transform:none;
        }
        .todoCard{
            cursor: pointer;
            background-color: var(--v-pale_purple-base);
            &:hover {
                background-color: #cdc8f1;
            }
        }
    }
</style>