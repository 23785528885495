<template>
    <v-dialog content-class="dialogItemCreate" persistent v-model="openAddForm">
        <v-card>
            <v-toolbar tile flat dark  class="bg-gradient" height="64" style="height:80px;">
                <div style="display:flex; width:100%; height:64px; align-items:center;">
                    <v-btn depressed color="transparent" style="position:absolute;" class="mr-2" @click.native="goBack"><v-icon dark small class="mr-2">$prev</v-icon> {{ $t("back") }}</v-btn>
                    <v-toolbar-title class="d-flex align-center justify-center toolbarTitle">{{$t('addNew')}}</v-toolbar-title>
                </div> 
            </v-toolbar>
            <v-card-text>
                <div v-if="moduleBtn.length == 0" class="d-flex flex-wrap pa-12 justify-center">
                    <template v-for="(btn, index) in baseBtns">
                        <v-card :class="(btn.hover ? 'hover' : '')+' cardBtn mx-2 my-2'" @click="selectModule(btn)" @mouseover="btn.hover = true" @mouseout="btn.hover = false" v-if="btn.menuRight ? $func.hasRight(btn.menuRight) : true">
                            <v-card-text class="d-flex justify-center " style="height:100%;">
                                <div class="d-flex flex-column justify-space-between">
                                    <v-img width="150" height="100" class="flex-grow-0" contain :src="(btn.icon && btn.icon != '' ? (`${publicPath}` + 'img/' + btn.icon) : (btn.url && btn.url != '' ? btn.url : default_icon))"></v-img>
                                    <h4>{{$t(btn.label)}}</h4>
                                </div>
                            </v-card-text>
                        </v-card>
                    </template>
                </div>
                <div v-else class="d-flex flex-wrap pa-12 justify-center">
                    <template v-for="(btn, index) in moduleBtn">
                        <v-card :class="(btn.hover ? 'hover' : '')+' cardBtn mx-2 my-2'" @click="openForm(btn)" @mouseover="btn.hover = true" @mouseout="btn.hover = false" v-if="btn.menuRight ? $func.hasRight(btn.menuRight) : true">
                            <v-card-text class="d-flex justify-center " style="height:100%;">
                                <div class="d-flex flex-column justify-space-between">
                                    <v-img width="150" height="100" class="flex-grow-0" contain :src="(btn.icon && btn.icon != '' ? (`${publicPath}` + 'img/' + btn.icon) : default_icon)"></v-img>
                                    <h4>{{$t(btn.name)}}</h4>
                                </div>
                            </v-card-text>
                        </v-card>
                    </template>
                </div>
            </v-card-text>
        </v-card>
        <FormDialogComponent
        v-if="form"
        ref="componentForm"
        :form="form"
        :action="'SET_DATA'"
        />
    </v-dialog>
</template>
<script>
import GenericDataService from '@/services/GenericDataService'
import FormDialogComponent from "@/components/dialogs/FormDialogComponent";
import getForm from "@/mixins/mixins";
export default {
    name:"addContactItemDialogComponent",
    components:{FormDialogComponent},
    mixins:[getForm],
    data() {
        return {
            form:null,
            default_icon: "https://cdn.media.upyourbizz.com/data/DEMO/6b/ba/c9/df6e5d6753-168180845811.png",
            baseBtns: [
                {
                    label: "lead",
                    moduleName: "demand",
                    form: "\/superlead\/getForm?formName=step1&postdata[contact_id]=" + this.$route.params.contact_id,
                    menuRight: "lead\/add",
                    hover: false
                },
                {
                    label: "product",
                    moduleName: "product",
                    selectUrl: "/hard_value/getValuesArrayByType?type=items",
                    url: "https://cdn.media.upyourbizz.com/data/DEMO/34/1a/9e/6e61e02ccb-168180464209.png",
                    hover: false
                }
            ],
            moduleBtn: [],
            moduleName: ""

        }
    },
    methods: {
        selectModule(btn){
            this.moduleName = btn.moduleName

            if(btn.selectUrl) {
                GenericDataService.getData(btn.selectUrl).then((response) => {
                    let test = response.data.data;
                    test.forEach(element => { element.hover = false; })
                    this.moduleBtn = test;
                });
            } else if (btn.form) {
                this.getForm(btn.form);
                this.openAddForm = false;
            }
        },
        goBack() {
            this.moduleName = ""
            if(this.moduleBtn.length > 1){
                this.moduleBtn = []
            } else {
                this.openAddForm = false;
            }
        },
        customRefresh(response){
            if(this.moduleName == 'demand')
                this.$store.dispatch("lead/SET_SUPERLEAD_AND_OPEN_DIALOG", response.data.data);
            else
                this.$emit('refresh', this.moduleName, response.data.data)
            
            this.moduleName = ""
            this.moduleBtn = []
            this.openAddForm = false;
        },
        openForm(btn){
            if(this.moduleName == 'product') {
                let url = "/contact/" + this.$route.params.contact_id + "/getItemForm?type="+btn.type
                if(this.$route.params.operation_id)
                    url = url+"&operation_id="+this.$route.params.operation_id
                this.getForm(url)
            }
            this.moduleName = "";
            this.moduleBtn = [];
            this.openAddForm = false;
        }
    },
    computed: {
        openAddForm: {
            get: function(){
                return this.$store.state.contact.addForm;
            },
            set: function(val){
                this.$store.dispatch("contact/OPEN_ADD_FORM", false);
            }
        },
    },
}
</script>
<style lang="scss">
    .dialogItemCreate {
        width:unset !important;
        max-width:1150px !important;
        min-width:500px !important;
    }
    .toolbarTitle {
        width:100%;
    }
    .cardBtn {
        max-width:180px !important;
        h4 {
            border-bottom:0;
            text-transform: none;
            text-align: center;
            font-size:18px;
        }
        
    }
</style>