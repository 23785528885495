<template>
    <v-expansion-panel class="no-shadow expansionVehicle">
        <v-expansion-panel-header hide-actions>
            <h3 class="icon-title">
                <span style="display:flex; width:100%; align-items:center; justify-content:space-between;">
                    <div style="font-size:18px;">
                        <span class="primary--text mr-1">#V{{item.id.value}}</span> - {{item.label.value}}
                    </div>
                    <v-icon :class="value == index ? 'mb-0 ml-2 expand-icon' : 'mb-0 ml-2 expand-icon inactive'">$dropdown</v-icon>
                </span>
                <span style="min-width:75px;">
                    <v-btn v-if="this.$func.hasRight('lead/update')" @click.stop="$emit('editForm')" depressed text class="square">
                        <v-icon dark small color="primary"> $pencil_d </v-icon>
                    </v-btn>

                    <v-btn v-if="this.$func.hasRight('lead/update')" @click.stop="$emit('deleteForm')" depressed text class="square">
                        <v-icon dark small color="primary"> $trashalt_d </v-icon>
                    </v-btn>
                </span>
            </h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <table>
                <tr v-for="(data, index) in itemCleaned" :key="'data_'+index" v-show="data.value && data.value != ''">
                    <td class="text-right"><p class="mb-0"><b>{{$t(index)}} :</b></p></td>
                    <td class="text-left" v-if="data.type == 'date'"><p class="mb-0">{{parseUsDateToFr(data.value)}}</p></td>
                    <td class="text-left" v-else-if="data.type == 'datetime'"><p class="mb-0">{{parseFullDateToFr(data.value)}}</p></td>
                    <td class="text-left" v-else><p class="mb-0">{{data.value}}<span v-if="data.suffix">{{data.suffix}}</span></p></td> 
                </tr>
                <tr v-for="(option, index) in item.options.value" :key="index" class="block">
                    <td class="text-right"><p class="mb-0"><b>{{$t('item'+item.type.value+'.'+option.reference)}} :</b></p></td>
                    <td class="text-left"><p class="mb-0">{{option.value}}</p></td>
                </tr>
            </table>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>
<script>
import dateManipulation from '@/mixins/dateManipulation'

export default {
    name: "productComponent",
    props:['item', 'index', "value"],
    mixins:[dateManipulation],
    data() {
        return {
            
        }
    },
    computed: {
        itemCleaned(){
            let newItem = this._.cloneDeep(this.item)
            delete newItem.uniq_id
            delete newItem.type
            delete newItem.label
            delete newItem.options
            delete newItem.id

            return newItem
        }
    },
}
</script>
<style lang="scss">
    
</style>