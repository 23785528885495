<template>
    <div >
        <v-menu :value="shown" allow-overflow @input="onMenuToggle" offset-y min-width="500" :attach="(attach ? attach : false)" :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
                <span  v-bind="attrs" v-on="on" @click="shown = true">
                    <v-text-field hide-details flat solo :value="textModel" :key="'text_'+boxKey" class="pt-0 mt-0" :label="$t('selectActivityCategory')">
                        <template v-slot:append>
                            <div>
                                <!-- <v-icon @click="clearField" small v-if="value.length">
                                    $close
                                </v-icon> -->
                                <v-icon small class="ml-1 calendarIcon">
                                    $caretdown_s
                                </v-icon>
                            </div>
                        </template>
                    </v-text-field>
                </span>
            </template>
        <div class="menuPeriod">
                <v-row dense class="ma-4">
                    <v-col cols="4" v-for="(subcat, cat) in categories" :key="'list_'+cat+boxKey">
                        <h5 style="text-transform: uppercase; font-size:16px;">
                            <v-checkbox
                                v-model="catSelected"
                                :label="$t(cat)"
                                dense
                                hide-details
                                :key="cat+boxKey"
                                @click="toggleFullCategory(cat)"
                                :value="cat"
                                ></v-checkbox>
                            </h5>
                        <ul style="list-style:none;">
                            <li v-for="(sub, index) in subcat" :key="'sub_'+cat+'.'+sub+boxKey">
                                <v-checkbox
                                v-model="selectedBoxes"
                                :label="$t(sub)"
                                @change="setCategory"
                                hide-details
                                dense
                                :value="cat+'.'+sub"
                                ></v-checkbox>

                            </li>
                        </ul>
                    </v-col>
                    <v-col cols="12" class="text-right">
                        <v-btn depressed small color="primary" @click="filterCat">{{ $t('filter') }}</v-btn>
                    </v-col>
                </v-row>
        </div>
        </v-menu>
    </div>
</template>

<script>
import validate from '../functions/validations'
import dateManipulation from '@/mixins/dateManipulation'
export default {
  props: [ "value", "attach"],
  mixins: [validate, dateManipulation],
  data() {
    return {
      datetime: "",
      shown:false,
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD', // Uses 'iso' if missing
      },
      textFieldProps: {},
      selectedBoxes: [],
      catSelected:[],
      boxKey:0,
      categories : {
        "team": [
            "contact",
            "demand",
            "rdv",
            "superlead"
        ],
        "marketing":[
            "appels",
            "activity",
            "workflow",
            "retroplanning_sent",
            "contact"
        ],
        "backoffice": [
            "contact",
            "demand",
            "rdv",
            "superlead"
        ],
    }
    };
  },
  computed: {
      textModel(){
          return this.selectedBoxes.length +'/12 '+this.$t('activity');
      }
  },
  watch: {

  },
  methods: {
    filterCat(){
        this.$emit('filterCat', this.selectedBoxes);
    },
    onMenuToggle($evt){
        if(!$evt){
            if(!this._.isEqual( this.value, this.selectedBoxes))
                this.selectedBoxes = this._.cloneDeep(this.value)

            let catToDelete = [];
            this.catSelected.forEach((element, index) => {
                let founded = this.selectedBoxes.find((e) => e.includes(element))
                if(!founded)
                    catToDelete.push(element)
            });
            this._.remove(this.catSelected, function(e) {if(catToDelete.includes(e)) return e});
            this.boxKey++
        }
        
    },
    toggleFullCategory(cat) {
        let founded = this.selectedBoxes.find((e) => e.includes(cat))
        let allSelectedCat = this.selectedBoxes.filter((e) => e.includes(cat)).length == this.categories[cat].length
        if(founded && allSelectedCat){
            if(!this.catSelected.find((e) => e == cat))
                 this._.remove(this.selectedBoxes, function(e) {return e.includes(cat)});
        } else {
            this.categories[cat].forEach(element => {
                if(!this.selectedBoxes.includes(cat+'.'+element))
                    this.selectedBoxes.push(cat+'.'+element);
            });
        }
        this.boxKey++
    },
    setCategory($evt){
        $evt.forEach(catSelect => {
            let cat = catSelect.split('.')[0]
            if($evt.filter((e) => e.includes(cat)).length == this.categories[cat].length)
                this.catSelected.push(cat);
            else {
                if(this.catSelected.includes(cat))
                    this._.remove(this.catSelected, function(e) {return e == cat});
            } 
            this.boxKey++
        });
    },
    close(){
        this.shown = false;
    }
  }
};
</script>

<style lang="scss">
    .btnRangeDate {
        height:24px !important;
        text-transform: none !important;
    }
    .calendarIcon {
        cursor: pointer;
    }
    .menuPeriod {
        overflow:hidden; 
        margin-top:1px; 
        background-color:#FFF;
        .calendarInput {
            background-color:transparent; 
            border:none;
        }
    }
    .presetDiv{
        overflow:hidden; 
        overflow-y:scroll; 
        width:100%; 
        height:100%; 
        position:relative; 
        border-left:1px solid rgba(0,0,0,0.2);
        .presetDivGroup {
            position:absolute; 
            display:flex; 
            flex-direction:column; 
            width:100%;
        }
        &::-webkit-scrollbar-track
        {
            -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.3);
            background-color: #F5F5F5;
        }

        &::-webkit-scrollbar
        {
            width: 10px;
            background-color: #F5F5F5;
        }

        &::-webkit-scrollbar-thumb
        {
            background-color: var(--v-primary-base);
        }
        .v-list-item--link:before{
            opacity:0;
        }
        .v-list-item--link:hover, .v-list-item:not(.v-list-item--active):not(.v-list-item--disabled):hover{
            background-color: var(--v-primary-base);
            color:white !important;
            opacity:1;
            // &:hover{
            //     background-color: var(--v-primary-base);
            //     opacity:1;
            // }
        }
    }
</style>
