<template>
    <div class="activityTab">
        <v-row v-if="loading" class="">
            <v-col cols="12" class="text-center py-6 px-4">
                <v-progress-circular
                :size="50"
                :width="7"
                color="primary"
                class="ml-4"
                indeterminate
                ></v-progress-circular>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col cols="12" class="d-flex align-center mt-3">
                <div class="mr-2">
                    {{ $t('filterActivity') }} :
                </div>
                <div  class="input-form mr-4">
                    <vDateRangePicker :props="{solo: true, flat: true}" @change="customRefresh(true)" v-model="filters.period" style="width:250px;"/>
                </div>
                <div >
                    <vCategoryactivityPicker class="input-form" ref="filterCategories" :value="filters.cat" @filterCat="filterCat" />
                </div>
            </v-col>
            <v-col cols="12" class="px-8 pt-2" v-if="history && Object.keys(history).length > 0" >
               <div v-for="(historyLog, monthYear) in history" :key='monthYear' class="mt-4">
                    <h2 class="pink--text">{{  timestampToDate((Date.UTC(monthYear.split('-')[0], monthYear.split('-')[1] - 1)/1000), 'monthYear') }} </h2>
                    <v-timeline align-top dense>
                        <v-timeline-item :color="'white'"  v-for="(item, i) in historyLog" :key="'activity_'+i">
                            <template v-slot:icon>
                                <v-icon small color="primary" v-if="item.subcategorie == 'appels'">
                                   $phone_d
                                </v-icon>
                                <v-icon small color="primary" v-else-if="item.subcategorie == 'rdv' || item.subcategorie == 'passage' || item.subcategorie == 'inscription'">
                                   $calendar_check_d
                                </v-icon>
                                <v-icon small color="primary" v-else-if="item.subcategorie == 'contact'">
                                   $usercheck_d
                                </v-icon>
                                <v-icon small color="primary" v-else>
                                   $spinner_d
                                </v-icon>
                            </template>
                            <template v-slot:opposite>
                                <div class="dayMonthLog"><b class="primary--text ">{{ timestampToDate((new Date(item.ts).getTime() / 1000), 'dayMonth') }}</b></div>
                            </template>
                            <v-expansion-panels>
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    <div class="d-flex justify-content-start">
                                        <h4 class="pb-0 mr-2">{{ $t(item.action) }}&nbsp;</h4>
                                        <span v-if="item.user_name && item.user_name == 'LANDING PAGE' " class="itemBy">{{ $t('by') +' Lexxy ' }}</span>
                                        <span v-if="item.user_name && item.user_name != 'LANDING PAGE'" class="itemBy">{{ $t('by') +' '+ item.user_name }}</span>
                                        
                                    </div>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <div v-if="item.data.title">
                                        <span v-if="item.data.title.type && item.data.title.type == 'date'">
                                            ({{parseFRDateToDayMonthAndHours(item.data.title.content)}})
                                        </span>
                                        <span v-else-if="item.data.title.content">
                                            ({{ $t(item.data.title.content) }})
                                        </span>
                                    </div>
                                    <div v-if="item.data.subtitle">
                                        <span v-if="item.data.subtitle.type && item.data.subtitle.type == 'date'">
                                            {{$t('the') +" "+ parseFRDateToDayMonthAndHours(item.data.subtitle.content)}}
                                        </span>
                                        <span v-else-if="item.data.subtitle.content">
                                            {{ $t(item.data.subtitle.content) }}
                                        </span>
                                        <span v-else-if="item.data.subtitle">
                                            {{ item.data.to_trad ? $t(item.data.subtitle) : item.data.subtitle }}
                                        </span>
                                    </div>
                                    <div v-if="item.data && item.data.name">
                                        <span class="grey--text">{{ $t('name') }} :</span> {{ item.data.name }}
                                    </div>
                                    <div v-if="item.data && item.data.started_at">
                                        <span class="grey--text">{{ $t('call_started') }} :</span> {{ timestampToDate(item.data.started_at.toString().substring(0, 10), 'long') }}
                                    </div>
                                    <div v-if="item.data && item.data.ended_at">
                                        <span class="grey--text">{{ $t('call_ended') }} :</span> {{ timestampToDate(item.data.ended_at.toString().substring(0, 10), 'long') }}
                                    </div>
                                    <div v-if="item.data && item.data.duration">
                                        <span class="grey--text">{{ $t('call_duration') }} :</span> {{ item.data.duration }}s
                                    </div>
                                    <div v-if="item.data && item.data.comment">
                                        <span class="grey--text">{{ $t('comment') }} :</span> {{ item.data.comment }}
                                    </div>
                                    <div v-if="item.data && item.data.vendor && item.action!='transfer_demand'">
                                        <v-icon small class="mr-2">$usertie_d</v-icon><span class="grey--text">{{ $t('vendor') }} :</span> {{ item.data.vendor }}
                                    </div>
                                    <div v-if="item.data && item.data.vendor && item.action == 'transfer_demand'">
                                        <v-icon small class="mr-2">$usertie_d</v-icon><span v-if="item.data.old_vendor"><span class="grey--text">{{ $t('transferFrom') }} </span> {{ item.data.old_vendor }}</span><span class="grey--text">&nbsp;{{ $t('for') }} </span> {{ item.data.vendor }}
                                    </div>
                                    <div v-if="item.data && item.data.workflow">
                                        <span class="grey--text">{{ $t('workflow') }} :</span> {{ item.data.workflow }}
                                    </div>
                                    <div v-if="item.data && item.data.urls">
                                        <v-simple-table dark dense  :key="type" class="mb-3 mt-2 mx-1">
                                            <thead>
                                                <tr>
                                                    <th class="text-left">{{ $t('url') }}</th>
                                                    <th class="text-left">{{ $t('duration') }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <template v-for="(url, field) in item.data.urls">
                                                    <tr>
                                                        <td>{{ (url.url) }}</td>
                                                        <td>{{ (url.duration) }}</td>
                                                    </tr>
                                                </template>
                                            </tbody>
                                        </v-simple-table>
                                    </div>
                                    <span v-if="item.data && item.data.delta">
                                        <v-simple-table dark dense v-for="(datas, type) in item.data.delta" :key="type" class="mb-3 mt-2 mx-1">
                                            <template v-slot:default>
                                                <thead>
                                                    <tr>
                                                    <th class="text-left">{{ $t('fields') }}</th>
                                                    <th v-if="type === 'new' || type === 'delete'" class="text-left">{{ $t('value') }}</th>
                                                    <th v-if="type === 'update'" class="text-left">{{ $t('before') }}</th>
                                                    <th v-if="type === 'update'" class="text-left">{{ $t('after') }}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <template v-for="(data, field) in datas">
                                                        <tr v-if="data && !(field).includes('__')" :key="field">
                                                            <td>{{ $t(field) }}</td>
                                                            <td v-if="type === 'new' && field != 'phones'">{{ data ? data : '-' }}</td>
                                                            <td v-if="type === 'update' && field != 'phones'">{{ data.before ? data.before : '-' }}</td>
                                                            <td v-if="type === 'update' && field != 'phones'">{{ data.after ? data.after : '-' }}</td>
                                                            <td v-if="type === 'delete'">{{ data ? data : '-' }}</td>
                                                            <td v-if="type === 'new' && field == 'phones'">
                                                            <span v-if="data">
                                                                <span v-for="(phone, index) in JSON.parse(data)" :key="index" :class="index > 0 ? 'mt-2' : ''">
                                                                <v-chip outlined small style="text-transform:uppercase" class="mr-2">{{phone.type}}</v-chip>  
                                                                {{phone.number}}
                                                                </span>  
                                                            </span>
                                                            <span v-else>-</span>
                                                            </td>
                                                            <td v-if="type === 'update' && field == 'phones'">
                                                            <span v-if="data.before && data.before != '[]'">
                                                                <span v-for="(phone, index) in data.before" :key="index" :class="index > 0 ? 'mt-2' : ''">
                                                                <v-chip outlined x-small style="text-transform:uppercase" class="mr-2">{{phone.type}}</v-chip>  
                                                                {{phone.number}}
                                                                </span>  
                                                            </span>
                                                            <span v-else>-</span>
                                                            </td>
                                                            <td v-if="type === 'update' && field == 'phones'">
                                                            <div v-if="data.after && data.after != '[]'" class="my-1">
                                                                <span v-for="(phone, index) in data.after" :key="index" :class="index > 0 ? 'mt-2' : ''">
                                                                <v-chip outlined x-small style="text-transform:uppercase" class="mr-2">{{phone.type}}</v-chip>  
                                                                {{phone.number}}
                                                                </span>  
                                                            </div>
                                                            <span v-else>-</span>
                                                            </td>
                                                        </tr>
                                                    </template>
                                                </tbody>
                                            </template> 
                                        </v-simple-table>
                                    </span>
                                    <v-divider class="my-3"></v-divider>
                                    <div class="d-flex justify-space-between">
                                        <div>
                                            <v-chip outlined color="purple" small v-if="item.data && item.data.date_start">
                                                {{ parseFullDateToFr(item.data.date_start) }}
                                            </v-chip>
                                        </div>
                                        <div>
                                            <v-chip small outlined v-if="item.data && item.data.is_success" :color="item.data.is_success=='FAIL' ? 'error' : 'success'">{{ $t(item.data.is_success) }}</v-chip>
                                            
                                            <v-btn  depressed text v-if="item.data && item.data.event" @click="launchEvent(item)" class="square">
                                                <v-icon color="pink">$eye_d</v-icon>
                                            </v-btn>
                                            <v-btn  depressed text v-else-if="item.data && item.data.link" @click="$emit('gotToLink', item.data.link, item.categorie)" class="square">
                                                <v-icon color="pink">$eye_d</v-icon>
                                            </v-btn>
                                            
                                        </div>
                                    </div>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            </v-expansion-panels>
                        </v-timeline-item>

                    </v-timeline>
               </div>
                <div v-if="nbPage > 1">
                    <v-pagination
                        v-model="paginationNum"
                        class="my-4"
                        :length="nbPage"
                    ></v-pagination>
                </div>
            </v-col>
            
            <v-col cols="12" v-else>
                <v-alert class="mt-3" border="left" color="info" text>
                    {{$t('emptyHistoryInfoMessage')}}
                </v-alert>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import GenericDataService from '@/services/GenericDataService'
import vCategoryactivityPicker from '../../../components/forms/inputs/v-categoryactivity-picker.vue'
import vDateRangePicker from '../../../components/forms/inputs/v-daterange-picker.vue'
import dateManipulation from '@/mixins/dateManipulation'
export default {
    name: "activityTabComponent",
    mixins: [dateManipulation],
    components:{
        vDateRangePicker,
        vCategoryactivityPicker
    },
    data() {
        return {
            loading:true,
            loaderInfinite: false,
            endScroll:false,
            history: [],
            totalItems: 0,
            pageNum:0,
            filterKey:0,
            pageLen : 20,
            filters: {
                period: {
                    start: "",
                    end: ""
                },
                cat: []
            },
            nbPage:0
        }
    },
    created(){
        GenericDataService.postData("/contact/" + this.$route.params.contact_id + "/getActivityLog?pageNum="+this.pageNum, {filters : this.filters}).then((response) => {
            this.history = response.data.data.log
            this.cleanHistory()
            this.totalItems = response.data.data.totalItems
            this.nbPage = response.data.data.nbOfPages
            this.loading = false
        })
    },
    computed: {
        paginationNum: {
            get: function(){
                return this.pageNum+1;
            },
            set: function(val){
                this.pageNum = val - 1;
            }
        }
    },
    watch: {
        pageNum(val){
            document.getElementsByClassName('contentContact')[0].scrollTo({ top: 0, behavior: 'smooth' })
            this.customRefresh();
        },
        $route(to, from) {
            GenericDataService.postData("/contact/" + this.$route.params.contact_id + "/getActivityLog?pageNum="+this.pageNum, {filters : this.filters}).then((response) => {
                this.history = response.data.data.log
                this.cleanHistory()
                this.totalItems = response.data.data.totalItems
                this.nbPage = response.data.data.nbOfPages
                this.loading = false
            })
        },
    },
    methods: {
        launchEvent(item){
            switch (item.data.event) {
                case 'openSuperlead':
                    GenericDataService.getData('superlead/'+item.data.id+'/get').then((response)=> {
                        this.$store.dispatch('lead/SET_SUPERLEAD_AND_OPEN_DIALOG', response.data.data)
                    });
                    break;
                case 'openSuperleadDemand':
                    GenericDataService.getData('superlead/getByDemandId?id=' + item.data.demand_id + ('&operation_id=' + (item.data.operation_id ? item.data.operation_id : ""))).then((response)=> {
                        this.$store.dispatch('lead/SET_SUPERLEAD_AND_OPEN_DIALOG', response.data.data)
                    });
                    break;
                case 'openSuperleadRdv':
                    GenericDataService.getData('superlead/getByRdvId?id=' + item.data.rdv_id + ('&operation_id=' + (item.data.operation_id ? item.data.operation_id : ""))).then((response)=> {
                        this.$store.dispatch('lead/SET_SUPERLEAD_AND_OPEN_DIALOG', response.data.data)
                    });
                    break;
                default:
                    break;
            }
        },
        filterCat($evt){
            this.filters.cat = this._.cloneDeep($evt)
            this.customRefresh(true);
            setTimeout(() => {
                this.$refs.filterCategories.close();
                this.filterKey++
            }, 200);
        },
        cleanHistory(){
            for (const date in this.history) {
                    const historydate = this.history[date];
                    historydate.forEach(element => {
                        if(element.data && element.data.delta && element.data.delta.new){
                            let toDelete = true
                            for (const key in element.data.delta.new) {
                                if (!key.includes("__")) {
                                    toDelete = false                            
                                }
                            }
                            if(toDelete)
                                delete element.data.delta.new
                        }
                    });
            }
            
        },
        customRefresh(resetPage = false){
            if(resetPage)
                this.pageNum = 0

            GenericDataService.postData("/contact/" + this.$route.params.contact_id + "/getActivityLog?pageNum="+this.pageNum, {filters : this.filters}).then((response) => {
                this.history = response.data.data.log
                this.cleanHistory()
                this.totalItems = response.data.data.totalItems
                this.nbPage = response.data.data.nbOfPages
                this.loading = false
            })
        }
    }
}
</script>
<style lang="scss">
    .activityTab {
        h2 {
            margin-left:160px;
            text-transform: capitalize;

        }
        h4 {
            text-transform: none;
            border-bottom:0;
        }
        .itemBy {
            font-size:14px;
        }
        .dayMonthLog {
            text-align: right;
            width:110px !important;
            margin-top:10px;
        }
        .v-timeline--dense .v-timeline-item__opposite {
            display: flex;
            align-self: flex-start;
        }

        .v-timeline-item__opposite {
            flex: none;
        }
        .v-timeline-item__dot {
            background-color: var(--v-primary-base) !important;
        }
        
        .v-timeline--dense:not(.v-timeline--reverse):before {
            left: 157px !important;
            background-color: var(--v-primary-base) !important;
        }
        .opposite-width-force {
            display: inline-block;
            width: 95px;
            text-align: right;
        }
    }
    /* line */
</style>