<template>
    <div class="exchangesTab mt-6">
        <exchangeHistoryComponent :historyKey="historyKey" />

        <v-row>
            <v-col cols="12">
                <v-divider></v-divider>
            </v-col>
        </v-row>

        <exchangeFormComponent @submited="historyKey++" />
    </div>
</template>

<script>
import exchangeFormComponent from "@/modules/exchanges/exchangesComponents/exchangeFormComponent";
import exchangeHistoryComponent from "@/modules/exchanges/exchangesComponents/exchangeHistoryComponent";

export default {
    name: "exchangesTabComponent",
    components: { exchangeFormComponent, exchangeHistoryComponent },
    data() {
        return {
            historyKey: 0
        }
    },
}
</script>

<style lang="scss">
.exchangesTab {
}
</style>