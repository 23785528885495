<template>
  <v-card >
    <v-card-text  class="pa-2">
      <idComponent :contact="contact" class="mb-2" @edit="getForm(formUrl)" :blacklistItems="blacklistItems" />
    </v-card-text>

    <FormDialogComponent
      v-if="form"
      ref="componentForm"
      :type="'edit'"
      :form="form"
      :action="'SET_DATA'"
    />
  </v-card>
</template>

<script>
import FormDialogComponent from "@/components/dialogs/FormDialogComponent";
import idComponent from "@/modules/contacts/contactsComponents/idComponent";
import getForm from "@/mixins/mixins";
import refreshData from "@/mixins/mixins";

export default {
  name: "ContactDetailIdentity",
  components: {
    FormDialogComponent,
    idComponent,
  },
  props: ['blacklistItems'],
  mixins: [getForm, refreshData],
  data() {
    return {
      form: null,
      formUrl: "/contact/getForm?id=" + this.$route.params.contact_id,
      apiUrl: "/contact/get?id=" + this.$route.params.contact_id,
      mutation: "contact/setContactData",
    };
  },
  created() {
    this.refreshData();
  },
  watch: {
    $route(to, from) {
      this.formUrl = "/contact/getForm?id=" + this.$route.params.contact_id;
      this.apiUrl = "/contact/get?id=" + this.$route.params.contact_id;
      this.refreshData();
    },
  },
  methods: {},
  computed: {
    contact: function () {
      return this.$store.state.contact.contact;
    },
  },
};
</script>

<style lang="scss">
  .titleIdComponent {
    border-bottom: 1px solid rgba(0,0,0,0.1)
  }
</style>